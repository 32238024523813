import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'

export default function ReactD3Wrap(methods) {
    const defaultMethods = {
        initialize(svg, data, options) { },
        update(svg, data, options) { },
        destroy() { }
    }

    class ReactD3Wrap extends Component {
        componentDidMount() {
            this.initialize.call(this, findDOMNode(this), this.props.data, this._getOptions(this.props.options))
            this.update.call(this, findDOMNode(this), this.props.data, this._getOptions(this.props.options))
        }

        componentDidUpdate() {
            this.update.call(this, findDOMNode(this), this.props.data, this._getOptions(this.props.options))
        }

        componentWillUnmount() {
            this.destroy.call(this)
        }

        _getOptions(propOps) {
            const options = (typeof propOps === 'undefined') ? {} : propOps
            return options
        }

        render() {
            const { className, width, height } = this.props
            let classes = 'd3-wrap'
            if (className) classes = classes.concat(' ', this.props.className)

            return <svg className={classes} />
        }
    }

    ReactD3Wrap.propTypes = {
        data: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]).isRequired,
        options: PropTypes.object
    }

    Object.assign(ReactD3Wrap.prototype, defaultMethods, methods)

    return ReactD3Wrap
}
