module Analysis.Pathways.AdjacencyMatrix

open Fable.Helpers.React
open Fable.Helpers.React.Props
open RemoteData
open Components.AdjacencyMatrix
open Shared
open Shared.Tree
open Analysis.Shared
open Types

let sortBy (ordering : AdjacencyMatrixOrdering) rows columns cells =
    let sortByName (node : Components.AdjacencyMatrix.MatrixNode) = node.Name
    let sortByEdgeCount (node : Components.AdjacencyMatrix.MatrixNode) = node.EdgeCount
    let sortByEdgeCountRev (node : Components.AdjacencyMatrix.MatrixNode) = - node.EdgeCount

    let sortByRowValue row =
        match List.filter (fun (cell : Cell) -> cell.Row = row.Id) cells with
        | [] -> 0.0
        | cells ->
            cells
            |> List.map (fun cell -> cell.Value)
            |> List.max

    let sortByColumnValue column =
        match List.filter (fun (cell : Cell) -> cell.Column = column.Id) cells with
        | [] -> 0.0
        | cells ->
            cells
            |> List.map (fun cell -> cell.Value)
            |> List.max

    let flatSort rows columns rowSort columnSort =
        ( rows |> List.collect Tree.flatten |> List.sortByDescending rowSort,
          columns |> List.collect Tree.flatten |> List.sortByDescending columnSort)

    let hierarchySort rows columns rowSort columnSort =
        ( rows
          |> List.map (Tree.sortBy rowSort)
          |> List.sortBy (Tree.getRootNode >> rowSort)
          |> List.collect Tree.flatten,
          columns
          |> List.map (Tree.sortBy columnSort)
          |> List.sortBy (Tree.getRootNode >> columnSort)
          |> List.collect Tree.flatten)

    match ordering with
    | Hierarchy -> hierarchySort rows columns sortByName sortByEdgeCountRev
    | EdgeCount -> flatSort rows columns sortByEdgeCount sortByEdgeCount
    | EdgeValue -> flatSort rows columns sortByRowValue sortByColumnValue

let renderAdjacencyMatrix pathways (pathwayTree : Reactome.PathwayTree) genes selectedGenes order dispatch =
    let geneTreeList =
        genes
        |> List.map (fun gene ->
            Leaf { Id = gene
                   Name = gene
                   Selected = Set.contains gene selectedGenes })

    let genesOfPathwayId id =
        let maybePathway =
            pathways
            |> List.tryFind (fun (pathway : Pathway)-> pathway.name = id)
        match maybePathway with
        | None -> []
        | Some pathway -> pathway.genes

    let matrixTreeList =
        let convertNode (node : Shared.Reactome.Node) =
            { Id = node.Id
              Name = node.Name
              Selected =
                match genesOfPathwayId node.Name with
                | [] -> false
                | genes -> Set.isSubset (Set.ofList genes) selectedGenes }
        match pathwayTree |> Tree.map convertNode convertNode with
        | Leaf node -> []
        | Branch (node, subtrees) -> subtrees

    let cells =
        pathways
        |> List.collect (fun (pathway : Enrichr.Pathway) ->
            match pathway.sourceId with
            | None -> []
            | Some id ->
                pathway.genes
                |> List.map (fun gene ->
                    { Row = id
                      Column = gene
                      Value = 1.0 - pathway.pValue }))

    let data =
        Components.AdjacencyMatrix.createModel
            matrixTreeList
            geneTreeList
            cells

    let config = {
        CellSize = 20
        TransitionDelay = 5
        TransitionDuration = 1000
    }

    Components.AdjacencyMatrix.render config data (sortBy order) (AdjacencyMatrixMsg >> dispatch)

let renderOrderingChoice msg (label : string) (active : bool) dispatch =
    match active with
    | true ->
        span [ ClassName "adjacency-matrix-ordering__choice selected" ] [ str label ]
    | false ->
        span [ ClassName "adjacency-matrix-ordering__choice" ; OnClick (fun event -> (AdjacencyMatrixOrderingChanged >> dispatch) msg) ] [ str label ]

let renderOrderingChoices (choices : List<AdjacencyMatrixOrdering * string>) (selected : AdjacencyMatrixOrdering) dispatch =
    let choices =
        choices
        |> List.map (fun choice ->
            let msg = fst choice
            let label = snd choice
            let active = msg = selected
            renderOrderingChoice msg label active dispatch)
    div [ ClassName "adjacency-matrix-ordering" ]
        (List.concat [
            [ span [ ClassName "adjacency-matrix-ordering__label" ] [ str "Sort matrix by:"] ]
            choices ])

let render genes selectedGenes pathways pathwayTreeResult order dispatch =
    match pathwayTreeResult with
    | RemoteData.NotAsked -> str ""
    | RemoteData.Loading -> Components.Loading.Loading
    | RemoteData.Failure error -> str ("Error: " + error)
    | RemoteData.Success pathwayTreeResult ->
        match pathwayTreeResult with
        | Error error -> str ("Error: " + error)
        | Ok pathwayTree ->
            let genes = genes |> List.distinct
            div [] [
                renderOrderingChoices Analysis.Shared.adjacencyMatrixOrderingChoices order dispatch
                renderAdjacencyMatrix pathways pathwayTree genes selectedGenes order dispatch
            ]
