module Analysis.Views

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Components.Help

open Shared
open Types

let view helpVisible (viewMode : ViewMode.ViewMode) (model : Model) dispatch =
    let isActive analysis =
        match (model.analysis, analysis) with
        | (PathwaysAnalysis _, PathwaysAnalysis _) -> true
        | (CellularComponentsAnalysis _, CellularComponentsAnalysis _) -> true
        | (MolecularFunctionsAnalysis _, MolecularFunctionsAnalysis _) -> true
        | (BiologicalProcessesAnalysis _, BiologicalProcessesAnalysis _) -> true
        | _ -> false

    let selectedGenes =
        match model.selectedGenes.IsEmpty with
        | true ->
            span [ Class "selected-genes__placeholder tag is-white" ] [ str "select genes by clicking on the gene names" ]
        | false ->
            model.selectedGenes
            |> Set.toList
            |> List.map (fun gene -> GeneSelection.renderGeneTagInSelectedGenes gene (Msg.GeneSelectionMsg >> dispatch))
            |> span []

    let tabs =
        div [ Class "tabs" ]
            [ ul []
                 [ li [ OnClick(fun _ ->
                            PathwaysAnalysis
                            |> SelectAnalysis
                            |> dispatch)
                        classList [ ("is-active", PathwaysAnalysis |> isActive) ] ]
                       [ a [] [ str "Pathways" ] ]
                   li
                       [ OnClick(fun _ ->
                             CellularComponentsAnalysis
                             |> SelectAnalysis
                             |> dispatch)
                         classList
                             [ ("is-active", CellularComponentsAnalysis |> isActive) ] ]
                       [ a [] [ str "Cellular components" ] ]
                   li
                       [ OnClick(fun _ ->
                             MolecularFunctionsAnalysis
                             |> SelectAnalysis
                             |> dispatch)
                         classList
                             [ ("is-active", MolecularFunctionsAnalysis |> isActive) ] ]
                       [ a [] [ str "Molecular functions" ] ]
                   li
                       [ OnClick(fun _ ->
                             BiologicalProcessesAnalysis
                             |> SelectAnalysis
                             |> dispatch)
                         classList
                             [ ("is-active", BiologicalProcessesAnalysis |> isActive) ] ]
                       [ a [] [ str "Biological processes" ] ] ] ]

    let renderAnalysis (analysis : Analysis) data renderer =
        match data with
        | RemoteData.NotAsked
        | RemoteData.Loading -> Components.Loading.Loading
        | RemoteData.Failure error -> str (sprintf "Error loading %s: %s" analysis.Info.ItemNamePlural error)
        | RemoteData.Success data ->
            div [] (renderer data)

    let renderedAnalyses =
        match model.analysis with
        | PathwaysAnalysis ->
            renderAnalysis model.analysis model.pathways (fun pathways ->
                Pathways.Views.render
                    pathways (PathwaysAnalysisMsg >> dispatch)
                    { HelpVisible = helpVisible
                      Analysis = model.analysis
                      Genes = model.genes
                      SelectedGenes = model.selectedGenes } )

        | CellularComponentsAnalysis ->
            renderAnalysis model.analysis model.cellularComponents (fun cellularComponents ->
                GeneOntology.Views.render
                    cellularComponents (CellularComponentsAnalysisMsg >> dispatch)
                    { HelpVisible = helpVisible
                      Library = Shared.Enrichr.GeneOntologyCellularComponent
                      Analysis = model.analysis
                      Genes = model.genes
                      SelectedGenes = model.selectedGenes } )

        | MolecularFunctionsAnalysis ->
            renderAnalysis model.analysis model.molecularFunctions (fun molecularFunctions ->
                GeneOntology.Views.render
                    molecularFunctions (MolecularFunctionsAnalysisMsg >> dispatch)
                    { HelpVisible = helpVisible
                      Library = Shared.Enrichr.GeneOntologyMolecularFunction
                      Analysis = model.analysis
                      Genes = model.genes
                      SelectedGenes = model.selectedGenes } )

        | BiologicalProcessesAnalysis ->
            renderAnalysis model.analysis model.biologicalProcesses (fun biologicalProcesses ->
                GeneOntology.Views.render
                    biologicalProcesses (BiologicalProcessesAnalysisMsg >> dispatch)
                    { HelpVisible = helpVisible
                      Library = Shared.Enrichr.GeneOntologyBiologicalProcess
                      Analysis = model.analysis
                      Genes = model.genes
                      SelectedGenes = model.selectedGenes } )

    let findAssaysButton =
        match viewMode with
        | ViewMode.ViewMode.Snapshot ->
            fragment [] []
        | ViewMode.ViewMode.Workflow ->
            div [ Class "gene-pathway-cart" ]
                [ button [ Class "button is-link"
                           Disabled (model.selectedGenes.Count = 0)
                           OnClick(fun _ -> dispatch FindAssays) ]
                      [ span [ Class "icon is-small" ] [ i [ Class "fas fa-flask" ] [] ]
                        helpBottom helpVisible
                            (div [ Style [ Width "200px" ] ] [ str "Find assays for selected pathways and genes." ])
                            [ span [] [ str "Find assays" ] ] ] ]

    div [ ]
        [ div [ Class "container is-fluid" ]
                [ div [ Class "analysis-header" ]
                      [ h1 [ Class "title is-1" ]
                            [ str ("Gene analysis for ")
                              i [ Class "title is-2 has-text-primary" ] [ str (String.concat ", " model.phenotypes) ] ]
                        findAssaysButton ]

                  div [ classList [ "selected-genes", true ; "without-action-button", (match viewMode with ViewMode.ViewMode.Snapshot -> true | _ -> false) ] ] [
                      span [ ClassName "selected-genes__label" ] [ str "Selected genes:" ]
                      selectedGenes ]

                  tabs
                  renderedAnalyses ] ]
