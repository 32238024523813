module Analysis.GeneOntology.Filters

open Analysis.Shared
open Types
open Components.DropDown.MultipleSelect

let filters : Filters =
    { combinedScore =
          { name = "Combined score"
            isActive = false
            items =
                [ { name = "<= 5"
                    isSelected = false
                    data = FilterItemData.CombinedScoreFilterItemData CombinedScoreFilterItemData.LessOrEqualto5 }
                  { name = "> 5"
                    isSelected = false
                    data = FilterItemData.CombinedScoreFilterItemData CombinedScoreFilterItemData.GreaterThan5 } ] }
      geneCount =
          { name = "Gene count"
            isActive = false
            items =
                [ { name = "<= 2 genes"
                    isSelected = false
                    data = FilterItemData.GeneCountFilterItemData GeneCountFilterItemData.LessOrEqualto2 }
                  { name = "> 2 genes"
                    isSelected = false
                    data = FilterItemData.GeneCountFilterItemData GeneCountFilterItemData.GreaterThan2 } ] } }

let filterFunctionOfFilterItemData filterItemData : GeneOntologyEnrichment -> bool =
    match filterItemData with
    | FilterItemData.CombinedScoreFilterItemData part ->
        match part with
        | LessOrEqualto5 -> fun item -> item.combinedScore <= 5.0
        | GreaterThan5 -> fun item -> item.combinedScore > 5.0
    | FilterItemData.GeneCountFilterItemData part ->
        match part with
        | LessOrEqualto2 -> fun item -> item.genes.Length <= 2
        | GreaterThan2 -> fun item -> item.genes.Length > 2

let filter (filters : Filters) items =
    let filterGroups =
        [ filters.combinedScore.GetSelectedItems()
          filters.geneCount.GetSelectedItems() ]
        |> List.map
               (fun filters ->
               [ for item in filters do
                     yield (filterFunctionOfFilterItemData item.data) ])
    if List.collect id filterGroups |> List.isEmpty then items
    else
        filterGroups
        |> List.map (fun filterGroup ->
            if List.isEmpty filterGroup then
                items |> Set.ofList
            else
                filterGroup
                |> List.collect (fun filter -> List.filter filter items)
                |> Set.ofList)
        |> Set.intersectMany
        |> Set.toList

let search (dataTable : Components.DataTable.Model<GeneOntologyEnrichment, SelectedGenes, GeneSelectionMsg>) items =
    List.fold (fun data (column : Components.DataTable.Column<GeneOntologyEnrichment, SelectedGenes, GeneSelectionMsg>) ->
        match column.isSearchable with
        | None -> data
        | Some { Input = None } -> data
        | Some { Input = Some keywords; Match = matchFunction } -> data |> List.filter (matchFunction keywords))
        items dataTable.columns

let filterAndSearch (filters : Filters) (dataTable : Components.DataTable.Model<GeneOntologyEnrichment, SelectedGenes, GeneSelectionMsg>) items =
    items
    |> filter filters
    |> search dataTable
