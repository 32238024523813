module AlleleSearch.State

open Elmish

open Types
open Filters
open DataTable

let initialState =
    { phenotypeInput = ""
      phenotypes = []
      alleles = RemoteData.NotAsked
      alleleDataTable = createAlleleDataTable()
      alleleFilters = None }

let update msg model =
    match msg with
    | PhenotypeInputChanged str ->
        { model with phenotypeInput = str }, Cmd.none, NoOp
    | PhenotypeInputKeyPressed charCode ->
        match charCode with
        | 13.0 ->
            model, RequestAlleles model.phenotypeInput |> Cmd.ofMsg, NoOp
        | _ ->
            model, Cmd.none, NoOp
    | SetPhenotypeInput str ->
        { model with phenotypeInput = str },
        RequestAlleles str |> Cmd.ofMsg,
        NoOp
    | RequestAlleles str ->
        match str.Trim() with
        | "" ->
            model, Cmd.none, NoOp
        | str ->
            let phenotypes =
                System.Text.RegularExpressions.Regex.Split(str, @"\s*,\s*")
                |> Array.toList
            { model with phenotypes = phenotypes
                         alleles = RemoteData.Loading },
            Cmd.ofAsync
                Server.api.getAlleles phenotypes
                ReceivedAlleles
                (sprintf "%A" >> Error >> ReceivedAlleles),
            NoOp
    | ReceivedAlleles(Ok alleles) ->
        let newModel =
            { model with alleles = RemoteData.Success alleles
                         alleleFilters = Some(createAlleleFilters alleles) }
        newModel, Cmd.none, NoOp
    | ReceivedAlleles(Error error) ->
        { model with alleles = RemoteData.Failure error }, Cmd.none, NoOp
    | FilterAlleles msg ->
        match model.alleleFilters with
        | None ->
            model, Cmd.none, NoOp
        | Some filters ->
            let newFilters =
                match msg with
                | Mutation msg ->
                    { filters with mutation = Components.DropDown.MultipleSelect.update msg filters.mutation }
                | Phenotype msg ->
                    { filters with phenotype = Components.DropDown.MultipleSelect.update msg filters.phenotype }
                | Zygosity msg ->
                    { filters with zygosity = Components.DropDown.MultipleSelect.update msg filters.zygosity }
                | AlleleType msg ->
                    { filters with alleleType = Components.DropDown.MultipleSelect.update msg filters.alleleType }
                | GeneOverlap msg ->
                    match filters.geneOverlap with
                    | None -> filters
                    | Some filter -> { filters with geneOverlap = Some (Components.DropDown.SingleSelect.update msg filter) }
            { model with alleleFilters = Some newFilters }, Cmd.none, NoOp
    | AlleleDataTableMsg msg ->
        let newModel, externalMsg = Components.DataTable.update msg model.alleleDataTable
        { model with alleleDataTable = newModel }, Cmd.none, NoOp
    | RunAnalysis ->
        match model.alleles with
        | RemoteData.Success alleles ->
            let genes =
                filterAlleles model.alleleFilters alleles
                |> List.map (fun allele -> allele.gene)
            model, Cmd.none, ExternalMsg.RunAnalysis (model, model.phenotypes, genes)
        | _ -> model, Cmd.none, NoOp
