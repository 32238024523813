namespace Components.DropDown

open Fable.Helpers.React
open Fable.Helpers.React.Props

module SingleSelect =

    type Item<'Data> =
        { name : string
          data : 'Data }

    type Model<'Data> =
        { name : string
          isActive : bool
          items : Item<'Data> list
          selectedItem : Item<'Data> option }

    type Msg<'Data> =
        | SetActive of bool
        | SelectItem of Item<'Data> * bool

    let update msg (model : Model<'Data>) =
        match msg with
        | SetActive state -> { model with isActive = state }
        | SelectItem (item, state) ->
            let selectedItem =
                match state with
                | true -> Some item
                | false -> None
            { model with isActive = false
                         selectedItem = selectedItem }

    let render model dispatch =
        div [ classList [ "dropdown", true
                          "is-active", model.isActive
                          "is-filtered", Option.isSome model.selectedItem ] ]
            [ div [ Class "dropdown-trigger" ]
                  [ button [ Class "button"
                             OnClick(fun _ ->
                                 not model.isActive
                                 |> SetActive
                                 |> dispatch) ]
                        [ span [] [ str model.name ]

                          span [ Class "icon is-small" ]
                              [ i [ Class "fas fa-angle-down" ] [] ] ]

                    div [ Class "dropdown-menu" ]
                        [ div [ Class "dropdown-content" ]
                              (model.items
                               |> List.map (fun item ->
                                      let isItemSelected = Option.map (fun selectedItem -> item = selectedItem) model.selectedItem |> Option.defaultValue false
                                      a [ classList [ "dropdown-item", true
                                                      "is-active", isItemSelected ]
                                          OnClick (fun _ ->
                                              (item, not isItemSelected) |> SelectItem |> dispatch) ] [ str (item.name) ])) ] ] ]

module MultipleSelect =

    type Item<'Data> =
        { name : string
          isSelected : bool
          data : 'Data }

    type Model<'Data> =
        { name : string
          isActive : bool
          items : Item<'Data> list }

        member this.GetSelectedItems() =
            List.filter (fun item -> item.isSelected) this.items

    type Msg<'Data> =
        | SetActive of bool
        | SelectItem of Item<'Data> * bool

    let update msg (model : Model<'Data>) =
        match msg with
        | SetActive state -> { model with isActive = state }
        | SelectItem (item, state) ->
            let newItems =
                model.items
                |> List.map (fun itm ->
                       if itm.name = item.name then { itm with isSelected = state }
                       else itm)
            { model with isActive = false
                         items = newItems }

    let render model dispatch =
        div [ classList [ "dropdown", true
                          "is-active", model.isActive
                          "is-filtered",
                          model.GetSelectedItems()
                          |> List.isEmpty
                          |> not ] ]
            [ div [ Class "dropdown-trigger" ]
                  [ button [ Class "button"
                             OnClick(fun _ ->
                                 not model.isActive
                                 |> SetActive
                                 |> dispatch) ]
                        [ span [] [ str model.name ]

                          span [ Class "icon is-small" ]
                              [ i [ Class "fas fa-angle-down" ] [] ] ]

                    div [ Class "dropdown-menu" ]
                        [ div [ Class "dropdown-content" ]
                              (model.items
                               |> List.map (fun item ->
                                      a [ classList [ "dropdown-item", true
                                                      "is-active", item.isSelected ]
                                          OnClick (fun _ -> (item, not item.isSelected) |> SelectItem |> dispatch) ]
                                        [ str (item.name) ] ) ) ] ] ]
