module Assays.Transfer

open Shared.InterMine
open Shared.Enrichr
open Shared.ToxCast
open Assays.Types

type Model =
    { Phenotypes : Phenotype list
      Genes : Genes
      Pathways : Pathways
      Assays : AssaysResult option }

let fromDomain (model : Assays.Types.Model) : Model =
    { Phenotypes = model.phenotypes
      Genes = model.genes
      Pathways = model.pathways
      Assays = RemoteData.toTransfer model.assays }

let toDomain (transferModel : Model) : Assays.Types.Model =
    { phenotypes = transferModel.Phenotypes
      genes = transferModel.Genes
      pathways = transferModel.Pathways
      assays = RemoteData.fromTransfer transferModel.Assays }
