module AlleleSearch.Types

open Shared
open RemoteData

type Phenotype = string

type Allele = InterMine.Allele

type Alleles = InterMine.Alleles

type AlleleFilterItemData =
    | MutationFilterItemData of Allele
    | PhenotypeFilterItemData of Allele
    | ZygosityFilterItemData of Allele
    | AlleleTypeFilterItemData of Allele
    | GeneOverlapInAllPhenotypesFilterItemData
    | GeneOverlapOnlyInPhenotypeFilterItemData of Phenotype

type AlleleFilters =
    { mutation : Components.DropDown.MultipleSelect.Model<AlleleFilterItemData>
      phenotype : Components.DropDown.MultipleSelect.Model<AlleleFilterItemData>
      zygosity : Components.DropDown.MultipleSelect.Model<AlleleFilterItemData>
      alleleType : Components.DropDown.MultipleSelect.Model<AlleleFilterItemData>
      geneOverlap : Components.DropDown.SingleSelect.Model<AlleleFilterItemData> option }

type AlleleFilterMsg =
    | Mutation of Components.DropDown.MultipleSelect.Msg<AlleleFilterItemData>
    | Phenotype of Components.DropDown.MultipleSelect.Msg<AlleleFilterItemData>
    | Zygosity of Components.DropDown.MultipleSelect.Msg<AlleleFilterItemData>
    | AlleleType of Components.DropDown.MultipleSelect.Msg<AlleleFilterItemData>
    | GeneOverlap of Components.DropDown.SingleSelect.Msg<AlleleFilterItemData>

type Model =
    { phenotypeInput : string
      phenotypes : Phenotype list
      alleles : RemoteData<Alleles, string>
      alleleDataTable : Components.DataTable.Model<Allele, unit, unit>
      alleleFilters : AlleleFilters option }

type ExternalMsg =
    | NoOp
    | RunAnalysis of Model * InterMine.Phenotypes * Enrichr.Genes

type Msg =
    | PhenotypeInputChanged of string
    | PhenotypeInputKeyPressed of float
    | SetPhenotypeInput of string
    | RequestAlleles of string
    | ReceivedAlleles of Result<Alleles, string>
    | FilterAlleles of AlleleFilterMsg
    | AlleleDataTableMsg of Components.DataTable.Msg<unit>
    | RunAnalysis
