module Snapshot.Types

open Shared.Snapshot
open RemoteData
open Workflow.Types

type Route =
    | AlleleSearch
    | Analysis
    | Assays

type Snapshot =
    { Id : SnapshotId
      Timestamp : System.DateTime
      Description : string
      WorkflowState : WorkflowState }

type Model =
    { Route : Route
      Snapshot : RemoteData<Snapshot, string> }

type ExternalMsg =
    | NoOp
    | LoadWorkflowRequested of SnapshotId
    | CloseSnapshot

type Msg =
    | LoadSnapshotRequested of SnapshotId
    | LoadSnapshotCompleted of Result<TransferSnapshot, string>
    | AlleleSearchSnapshot of AlleleSearch.Types.Msg
    | AnalysisSnapshot of Analysis.Types.Msg
    | AssaysSnapshot of Assays.Types.Msg
    | CloseSnapshot
