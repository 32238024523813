module Views

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Types
open Workflow.Types
open Components.ProgressBar

let renderSignInSignOut authState dispatch =
    let action =
        match authState with
            | Authenticated name ->
                div [] [
                    span [ Class "sign-in-out__name" ] [ str (name + " -") ]
                    span [ Class "sign-in-out__link" ; OnClick (fun _ -> dispatch SignOut) ] [ str "Sign out" ]
                ]
            | _ ->
                span [ Class "sign-in-out__link" ; OnClick (fun _ -> dispatch SignIn) ] [ str "Sign in" ]

    div [ Class "navbar-item" ]
        [ i [ Class "fas fa-user" ] []
          action ]

let renderSavedWorkflows authState dispatch =
    match authState with
        | Authenticated name ->
            div [ Class "navbar-item" ] [
                i [ Class "fas fa-arrow-circle-right" ] []
                span [ Class "sign-in-out__link" ; OnClick (fun _ -> dispatch (NavigateTo Route.SnapshotHistory)) ] [ str "My workflows" ]
            ]
        | _ ->
            span [ ] [ ]


let layout (model : Model) (dispatch : Msg -> unit)
    (content : Fable.Import.React.ReactElement) =
    div [] [ nav [ Class "navbar is-black" ]
                 [ div [ Class "navbar-brand" ]
                       [ a [ Class "navbar-item navbar-brand-link"
                             OnClick (fun _ -> dispatch (NavigateTo Route.Home)) ]
                             [ str "SynTox" ] ]
                   div [ Class "navbar-menu is-active" ]
                       [ div [ Class "navbar-end" ]
                             [ renderSignInSignOut model.AuthState dispatch
                               renderSavedWorkflows model.AuthState dispatch
                               div [ classList [ "navbar-item help-toggle", true
                                                 "active", model.HelpVisible ]
                                     OnClick(fun _ -> dispatch ToggleHelpVisible) ]
                                   [ i [ Class "fas fa-question-circle" ] []
                                     span [] [ str "Help" ] ]
                             ] ] ]
             main [ ]
                  [ content ] ]

let renderFooter dispatch =
    footer [ ClassName "page-footer" ]
         [ div [] [ span [ Class "brand" ] [ str "SynTox" ]
                    str " is developed by "
                    a [ Href "https://www.syngenta.com/" ] [ str "Syngenta" ]
                    str " and "
                    a [ Href "https://www.edelweissconnect.com/" ] [ str "EdelweissConnect" ]
                    str " and funded by "
                    a [ Href "https://www.syngenta.com/" ] [ str "Syngenta" ]
                    str "." ]
           div []
               [ a [ Class "legal" ; OnClick (fun _ -> dispatch (NavigateTo Route.DataSources)) ] [ str "Data sources" ]
                 a [ Class "legal" ; OnClick (fun _ -> dispatch (NavigateTo Route.TermsOfUse)) ] [ str "Terms of use" ]
                 a [ Class "legal" ; OnClick (fun _ -> dispatch (NavigateTo Route.PrivacyPolicy)) ] [ str "Privacy policy" ]
               ]
         ]

let renderHome model dispatch =
    let actions =
        match model.AuthState with
            | Authenticated name ->
                div [ Class "actions" ]
                    [ a [ Class "button is-primary is-large" ; OnClick (fun _ -> dispatch StartNewWorkflow) ] [ str "Start your search" ]
                      a [ Class "button is-warning is-large" ; OnClick (fun _ -> dispatch (NavigateTo Route.SnapshotHistory))  ] [ str "Saved workflows" ]
                    ]
            | _ ->
                div [ Class "actions" ]
                    [ a [ Class "button is-primary is-large" ; OnClick (fun _ -> dispatch StartNewWorkflow) ] [ str "Start your search" ]
                      span [ Class "separator" ] [ str "or" ]
                      a [ Class "button is-large sign-in" ; OnClick (fun _ -> dispatch SignIn)  ] [ str "Sign in to see your workflows" ]
                    ]

    div [ Class "homepage" ]
        [ div [ Class "container is-fluid" ]
              [ section [ Class "homepage__intro" ]
                      [ h1 [ ] [ str "SynTox" ]
                        div [ Class "lead" ] [ str "An interactive platform for identification, visualization, prioritization and testing of disease phenotype-associated genes and pathways. It supports toxicologist in the process of hypothesis generation and identification of toxicant-associated changes throughout development and lifespan of an organism." ]
                        progressBar Active Active Active
                        actions
                      ]
              ]
          section [ Class "homepage__step-details" ]
                  [ h1 [ Class "title" ] [ str "The Workflow" ]
                    div [ Class "step-details__step" ]
                        [ div [ Class "step__text" ]
                              [ div [ Class "step__header" ]
                                    [ div [ Class "step__header-number" ] [ str "1" ]
                                      div [ Class "step__header-title" ] [ str "Search for phenotype-associated genes" ]
                                    ]
                                div [ Class "step__content" ]
                                    [ str "The SynTox workflow starts with a search on one or more mammalian phenotypes of interest. A phenotype is defined as the observable morphological, physiological, behavioral and other characteristics of mammalian organisms that are manifested through development and lifespan. A list of genes most probably associated with a specific phenotype is retrieved. References and background information on the animals is provided for each of the retrieved genes. This includes original publications, information on the genetic background of the animals, used to gain a better understanding of the physiological role of the gene, the type of mutation, the zygosity of the animal and the allelic type and composition. Filters may be used to preselect genes of interest before continuing with the analysis in step 2." ]
                              ]
                          div [ Class "step__image" ]
                              [ img [ Src "/images/phenotype-search.png" ] ]
                        ]
                    div [ Class "step-details__step" ]
                        [ div [ Class "step__text" ]
                              [ div [ Class "step__header" ]
                                    [ div [ Class "step__header-number" ] [ str "2" ]
                                      div [ Class "step__header-title" ] [ str "Analyse and select genes & pathways" ]
                                    ]
                                div [ Class "step__content" ]
                                    [ str "Pathways, biological processes, molecular functions and cellular components associated with the selected genes in step 1 are identified. Three data sources: Wikipathways, Reactome and GeneOntology are used. Data can be filtered according to different criteria and relationships between items can be visualised in a matrix or a tree diagram taking the hierarchy of the pathways into account. Based on the performed analysis the list of genes of interest can be further narrowed down." ]
                              ]
                          div [ Class "step__image" ]
                              [ img [ Src "/images/analysis.png" ] ]
                        ]
                    div [ Class "step-details__step" ]
                        [ div [ Class "step__text" ]
                              [ div [ Class "step__header" ]
                                    [ div [ Class "step__header-number" ] [ str "3" ]
                                      div [ Class "step__header-title" ] [ str "Find available assays" ]
                                    ]
                                div [ Class "step__content" ] [ str "In the last step of the SynTox workflow different sources are mined to collect information on available assays that target the selected genes of interest. Currently ToxCast and DiscoverX are the two supported sources, but more will be added in future releases of the platform." ]
                              ]
                          div [ Class "step__image" ]
                              [ img [ Src "/images/assays.png" ] ]
                        ]
                    div [ Class "step-details__step" ]
                        [ div [ Class "step__text" ]
                              [ div [ Class "step__header" ]
                                    [ div [ Class "step__header-number" ] [ str "4" ]
                                      div [ Class "step__header-title" ] [ str "Save your workflows" ]
                                    ]
                                div [ Class "step__content" ] [ str "The platform offers two modes of work: incognito search with no possibility for history records retrieval, or user-specific registered space, with the possibility for recording and accessing historical workflows. The second option offers the advantage that snapshots of performed workflows can be stored at any time and can be revisited or shared with colleagues. However, this functionality requires the creation of a personal account and sign in." ]
                              ]
                          div [ Class "step__image" ]
                              [ img [ Src "/images/saved-wokflows.png" ] ]
                        ]
                    actions
                  ]
          renderFooter dispatch
        ]

let renderDataSources model dispatch =
    div [ Class "text-page" ]
        [ div [ Class "container is-fluid default" ]
              [ div [ Class "text-page__content" ]
                    [ h1 [ Class "title" ] [ str "Data sources" ]
                      div [] [ b [] [ str "Search for phenotype-associated genes" ] ]
                      p [] [ str "The presented information on mammalian pathology-gene relationships is based on the Mouse Genome Atlas, a database resource for the laboratory mouse, providing integrated genetic, genomic, and biological data to facilitate the study of human health and disease. The available data is accessed through "
                             a [ Href "http://www.mousemine.org/mousemine/begin.do" ; Target "_blank" ] [ str "MouseMine" ]
                             str "." ]
                      div [] [ b [] [ str "Genes & pathways analysis" ] ]
                      p []
                        [ str "Three data sources (Wikipathways, Reactome and GeneOntology) are used for identification of gene-pathway, gene-biological processes, gene-molecular functions and gene-cellular components relationships. This data is accessed via "
                          a [ Href "https://amp.pharm.mssm.edu/Enrichr/" ; Target "_blank"] [ str "Enrichr"]
                          str "."
                        ]
                      div [] [ b [] [ str "Available assays" ] ]
                      p []
                        [ str "Two sources are currently mined to collect information on available assays that target the selected genes of interest: "
                          a [ Href "https://www.epa.gov/chemical-research/toxicity-forecasting" ; Target "_blank"] [ str "ToxCast"]
                          str " and "
                          a [ Href "https://www.discoverx.com/home" ; Target "_blank"] [ str "DiscoverX"]
                          str ". More sources will be added in future releases of the platform."
                        ]
                    ]
              ]
          renderFooter dispatch
        ]


let renderTermsOfUse model dispatch =
    div [ Class "text-page" ]
        [ div [ Class "container is-fluid default" ]
              [ h1 [ Class "title" ] [ str "Terms of use" ]
                p [] [ str "Text ..." ]
              ]
          renderFooter dispatch
        ]


let renderPrivacyPolicy model dispatch =
    div [ Class "text-page" ]
        [ div [ Class "container is-fluid default" ]
              [ h1 [ Class "title" ] [ str "Privacy Policy" ]
                p [] [ str "Text ..." ]
              ]
          renderFooter dispatch
        ]

let renderProgressBar (model : Types.Model) dispatch =
    let isAuthenticated =
        match model.AuthState with
        | Authenticated _ -> true
        | _ -> false
    match model.WorkflowState with
    | AlleleSearch state ->
        let canSaveState = state.alleles.hasCompleted
        progressBarWithSaveStateButton isAuthenticated Active Inactive Inactive model.WorkflowState canSaveState model.SaveWorkflowState model.SaveWorkflowDescription model.HelpVisible dispatch
    | Analysis state ->
        let alleleSearch =
            match model.RouteState with
            | RouteState.AlleleSearch _ -> Active
            | _ -> Link (fun _ -> dispatch (NavigateTo Route.AlleleSearch))
        let analysis =
            match model.RouteState with
            | RouteState.Analysis _ -> Active
            | _ -> Link (fun _ -> dispatch (NavigateTo Route.Analysis))
        let canSaveState =
            [ state.Analysis.pathways.hasCompleted
              state.Analysis.cellularComponents.hasCompleted
              state.Analysis.molecularFunctions.hasCompleted
              state.Analysis.biologicalProcesses.hasCompleted ]
            |> List.exists (fun el -> el = false)
            |> not
        progressBarWithSaveStateButton isAuthenticated alleleSearch analysis Inactive model.WorkflowState canSaveState model.SaveWorkflowState model.SaveWorkflowDescription model.HelpVisible dispatch
    | Assays state ->
        let alleleSearch =
            match model.RouteState with
            | RouteState.AlleleSearch _ -> Active
            | _ -> Link (fun _ -> dispatch (NavigateTo Route.AlleleSearch))
        let analysis =
            match model.RouteState with
            | RouteState.Analysis _ -> Active
            | _ -> Link (fun _ -> dispatch (NavigateTo Route.Analysis))
        let assays =
            match model.RouteState with
            | RouteState.Assays _ -> Active
            | _ -> Link (fun _ -> dispatch (NavigateTo Route.Assays))
        let canSaveState = state.Assays.assays.hasCompleted
        progressBarWithSaveStateButton isAuthenticated alleleSearch analysis assays model.WorkflowState canSaveState model.SaveWorkflowState model.SaveWorkflowDescription model.HelpVisible dispatch

let view (model : Model) (dispatch : Msg -> unit) =
    let content =
        match model.RouteState with
        | RouteState.Home ->
            renderHome model dispatch
        | RouteState.DataSources ->
            renderDataSources model dispatch
        | RouteState.TermsOfUse ->
            renderTermsOfUse model dispatch
        | RouteState.PrivacyPolicy ->
            renderPrivacyPolicy model dispatch
        | RouteState.AlleleSearch alleleSearchModel ->
            div [ ] [ renderProgressBar model dispatch
                      AlleleSearch.Views.view model.HelpVisible ViewMode.ViewMode.Workflow alleleSearchModel (Msg.AlleleSearchMsg >> dispatch) ]
        | RouteState.Analysis analysisModel ->
            div [ ] [ renderProgressBar model dispatch
                      Analysis.Views.view model.HelpVisible ViewMode.ViewMode.Workflow analysisModel (Msg.AnalysisMsg >> dispatch) ]
        | RouteState.Assays assaysModel ->
            div [ ] [ renderProgressBar model dispatch
                      Assays.Views.view model.HelpVisible ViewMode.ViewMode.Workflow assaysModel (Msg.AssaysMsg >> dispatch) ]
        | RouteState.SnapshotHistory snapshots ->
            Snapshot.ViewsHistory.render snapshots dispatch
        | RouteState.Snapshot snapshot ->
            let navigateToSnapshotRoute (snapshot : Snapshot.Types.Snapshot) route =
                Link (fun _ -> dispatch (NavigateTo (Route.Snapshot (snapshot.Id, route))))
            Snapshot.Views.renderSnapshot snapshot (Msg.SnapshotMsg >> dispatch) navigateToSnapshotRoute
    layout model dispatch content
