module Snapshot.State

open Elmish

open Types

let update msg model =
    match msg with
    | LoadSnapshotRequested workflowSnapshotId ->
        { model with Snapshot = RemoteData.Loading }, Cmd.none, ExternalMsg.LoadWorkflowRequested workflowSnapshotId

    | LoadSnapshotCompleted snapshotResult ->
        match snapshotResult with
        | Error err ->
            { model with Snapshot = RemoteData.Failure err }, Cmd.none, NoOp
        | Ok transferSnapshot ->
            let snapshot =
                transferSnapshot
                |> Transfer.toDomain
            { model with Snapshot = RemoteData.Success snapshot }, Cmd.none, NoOp

    | CloseSnapshot ->
        model, Cmd.none, ExternalMsg.CloseSnapshot

    | AlleleSearchSnapshot msg ->
        match msg with
        | AlleleSearch.Types.FilterAlleles _
        | AlleleSearch.Types.AlleleDataTableMsg _ ->
            match model.Snapshot with
            | RemoteData.Success snapshot ->
                match snapshot.WorkflowState with
                | Workflow.Types.AlleleSearch state ->
                    let newAlleleSearchModel, cmd, externalMsg = AlleleSearch.State.update msg state
                    let newWorkflowState = Workflow.Types.WorkflowState.AlleleSearch newAlleleSearchModel
                    let newSnapshot = { snapshot with WorkflowState = newWorkflowState }
                    let newModel = { model with Snapshot = RemoteData.Success newSnapshot }
                    newModel, Cmd.map AlleleSearchSnapshot cmd, NoOp
                | Workflow.Types.Analysis state ->
                    let newAlleleSearchModel, cmd, externalMsg = AlleleSearch.State.update msg state.AlleleSearch
                    let newWorkflowState = Workflow.Types.WorkflowState.Analysis { state with AlleleSearch = newAlleleSearchModel }
                    let newSnapshot = { snapshot with WorkflowState = newWorkflowState }
                    let newModel = { model with Snapshot = RemoteData.Success newSnapshot }
                    newModel, Cmd.map AlleleSearchSnapshot cmd, NoOp
                | Workflow.Types.Assays state ->
                    let newAlleleSearchModel, cmd, externalMsg = AlleleSearch.State.update msg state.AlleleSearch
                    let newWorkflowState = Workflow.Types.WorkflowState.Assays { state with AlleleSearch = newAlleleSearchModel }
                    let newSnapshot = { snapshot with WorkflowState = newWorkflowState }
                    let newModel = { model with Snapshot = RemoteData.Success newSnapshot }
                    newModel, Cmd.map AlleleSearchSnapshot cmd, NoOp
            | _ ->
                model, Cmd.none, NoOp
        | _ ->
            // Disallow all other messages in snapshot mode
            model, Cmd.none, NoOp

    | AnalysisSnapshot msg ->
        match msg with
        | Analysis.Types.SelectAnalysis _
        | Analysis.Types.PathwaysAnalysisMsg _
        | Analysis.Types.CellularComponentsAnalysisMsg _
        | Analysis.Types.BiologicalProcessesAnalysisMsg _
        | Analysis.Types.MolecularFunctionsAnalysisMsg _ ->
            match model.Snapshot with
            | RemoteData.Success snapshot ->
                match snapshot.WorkflowState with
                | Workflow.Types.AlleleSearch state ->
                    // Unreachable state
                    model, Cmd.none, NoOp
                | Workflow.Types.Analysis state ->
                    let newAnalysisModel, cmd, externalMsg = Analysis.State.update msg state.Analysis
                    let newWorkflowState = Workflow.Types.WorkflowState.Analysis { state with Analysis = newAnalysisModel }
                    let newSnapshot = { snapshot with WorkflowState = newWorkflowState }
                    let newModel = { model with Snapshot = RemoteData.Success newSnapshot }
                    newModel, Cmd.map AnalysisSnapshot cmd, NoOp
                | Workflow.Types.Assays state ->
                    let newAnalysisModel, cmd, externalMsg = Analysis.State.update msg state.Analysis
                    let newWorkflowState = Workflow.Types.WorkflowState.Assays { state with Analysis = newAnalysisModel }
                    let newSnapshot = { snapshot with WorkflowState = newWorkflowState }
                    let newModel = { model with Snapshot = RemoteData.Success newSnapshot }
                    newModel, Cmd.map AnalysisSnapshot cmd, NoOp
            | _ ->
                model, Cmd.none, NoOp
        | _ ->
            // Disallow all other messages in snapshot mode
            model, Cmd.none, NoOp

    | AssaysSnapshot msg ->
        // Assays snapshot does not have any relevant messages
        model, Cmd.none, NoOp
