module AlleleSearch.Filters

open Fable
open Elmish.React
open Components.DropDown.MultipleSelect
open Components.DropDown.SingleSelect

open Types

let phenotypesOfAlleles (alleles : Allele list) =
    alleles
    |> List.map (fun allele -> allele.phenotype)
    |> List.distinct

let createAlleleFilters (alleles : Alleles) : AlleleFilters =
    let createTermFilterItems valueOfItem filterFunction =
        alleles
        |> List.distinctBy valueOfItem
        |> List.map (fun item ->
               { name = valueOfItem item
                 isSelected = false
                 data = filterFunction item })

    let phenotypes = phenotypesOfAlleles alleles

    let onlyInPhenotype =
        phenotypes
        |> List.map (fun phenotype ->
               { name = "Genes involved only in " + phenotype
                 data = GeneOverlapOnlyInPhenotypeFilterItemData phenotype })

    let inAllPhenotypes =
        { name = "Genes involved in all phenotypes"
          data = GeneOverlapInAllPhenotypesFilterItemData }

    { mutation =
        { name = "Mutation"
          isActive = false
          items =
              createTermFilterItems (fun a -> a.mutation)
                  MutationFilterItemData }
      phenotype =
        { name = "Phenotype"
          isActive = false
          items =
              createTermFilterItems (fun a -> a.phenotype)
                  PhenotypeFilterItemData }
      zygosity =
        { name = "Zygosity"
          isActive = false
          items =
              createTermFilterItems (fun a -> a.zygote) ZygosityFilterItemData }
      alleleType =
        { name = "Allele type"
          isActive = false
          items =
            createTermFilterItems (fun a -> a.alleleType)
                AlleleTypeFilterItemData }
      geneOverlap =
        if List.length phenotypes <= 1 then None
        else Some { name = "Gene overlap"
                    isActive = false
                    selectedItem = None
                    items = inAllPhenotypes :: onlyInPhenotype } }

let filterFunctionOfAlleleFilterItemData item : Allele -> bool =
    match item with
    | MutationFilterItemData allele -> fun a -> a.mutation = allele.mutation
    | PhenotypeFilterItemData allele -> fun a -> a.phenotype = allele.phenotype
    | ZygosityFilterItemData allele -> fun a -> a.zygote = allele.zygote
    | AlleleTypeFilterItemData allele -> fun a -> a.alleleType = allele.alleleType
    | GeneOverlapInAllPhenotypesFilterItemData -> fun a -> true
    | GeneOverlapOnlyInPhenotypeFilterItemData phenotype -> fun a -> true

let filterAlleles filters alleles =
    let phenotypes = phenotypesOfAlleles alleles
    match filters with
    | None -> alleles
    | Some filters ->
        let filterFunctionGroups =
            [ filters.mutation.GetSelectedItems()
              filters.phenotype.GetSelectedItems()
              filters.zygosity.GetSelectedItems()
              filters.alleleType.GetSelectedItems() ]
            |> List.map (fun filters -> [ for item in filters do yield (filterFunctionOfAlleleFilterItemData item.data) ])

        let filteredAlleles =
            filterFunctionGroups
            |> List.fold (fun alleles filterGroup ->
                if List.isEmpty filterGroup then alleles
                else filterGroup |> List.collect (fun filter -> List.filter filter alleles)) alleles

        match filters.geneOverlap with
        | None -> filteredAlleles
        | Some filter ->
            match filter.selectedItem with
            | None -> filteredAlleles
            | Some geneOverlapFilter ->
                let phenotypeGenesMap =
                    phenotypes
                    |> List.map
                        (fun phenotype ->
                            let genes =
                                filteredAlleles
                                |> List.filter (fun allele -> allele.phenotype = phenotype)
                                |> List.map (fun allele -> allele.gene)
                            (phenotype, genes |> Set.ofList) )
                    |> Map.ofList
                match geneOverlapFilter.data with
                | GeneOverlapInAllPhenotypesFilterItemData ->
                    let geneIntersection =
                        phenotypeGenesMap
                        |> Map.toList
                        |> List.map (fun (key, value) -> value)
                        |> Set.intersectMany
                    filteredAlleles
                    |> List.filter (fun allele -> geneIntersection.Contains allele.gene )
                | GeneOverlapOnlyInPhenotypeFilterItemData selectedPhenotype ->
                    let selectedPhenotypeGenes = phenotypeGenesMap.Item selectedPhenotype
                    let notSelectedPhenotypeGenes =
                        phenotypeGenesMap
                        |> Map.toList
                        |> List.filter (fun (key, value) -> key <> selectedPhenotype)
                        |> List.map (fun (key, value) -> value)
                        |> Set.unionMany
                    let onlySelectedPhenotypeGenes = selectedPhenotypeGenes - notSelectedPhenotypeGenes
                    filteredAlleles
                    |> List.filter (fun allele -> onlySelectedPhenotypeGenes.Contains allele.gene )
                | _ -> filteredAlleles
