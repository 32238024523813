module AlleleSearch.Views

open Fable
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Elmish.React

open Components.Help
open Components.Loading

open Filters
open Types

let renderDownloadButton label url (filename : string) =
    a [ Class "button is-link is-outlined is-small"
        Download filename
        Href url ] [ span [ Class "icon is-small" ]
                         [ i [ Class "far fa-arrow-alt-circle-down" ] [] ]
                     span [] [ str label ] ]

let renderAllelesOrError helpVisible viewMode model dispatch =
    let phenotypes = model.phenotypes
    let alleles = model.alleles
    let alleleDataTable = model.alleleDataTable

    let renderSingleSelectFilter filter dispatch =
        div [ Class "filter" ] [ Components.DropDown.SingleSelect.render filter dispatch ]

    let renderMultipleSelectFilter filter dispatch =
        div [ Class "filter" ] [ Components.DropDown.MultipleSelect.render filter dispatch ]

    let renderSingleSelectFilterSummary (filter : Components.DropDown.SingleSelect.Model<AlleleFilterItemData>) =
        span [ Class "filter-summary__filter" ]
            [ span [ Class "filter-summary__filter-name" ]
                   [ str filter.name ]
              span [ Class "filter-summary__filter-items" ]
                   [ filter.items
                    |> List.filter (fun item -> Option.map (fun selectedItem -> item = selectedItem) filter.selectedItem |> Option.defaultValue false)
                    |> List.map (fun item -> item.name)
                    |> String.concat ", "
                    |> str ] ]

    let renderMultipleSelectFilterSummary (filter : Components.DropDown.MultipleSelect.Model<AlleleFilterItemData>) =
        span [ Class "filter-summary__filter" ]
            [ span [ Class "filter-summary__filter-name" ]
                   [ str filter.name ]
              span [ Class "filter-summary__filter-items" ]
                   [ filter.items
                    |> List.filter (fun item -> item.isSelected)
                    |> List.map (fun item -> item.name)
                    |> String.concat ", "
                    |> str ] ]

    let filters =
        match model.alleleFilters with
        | None -> []
        | Some filters ->
            let res =
                [ renderMultipleSelectFilter filters.phenotype (Phenotype
                                                  >> Msg.FilterAlleles
                                                  >> dispatch)
                  renderMultipleSelectFilter filters.alleleType (AlleleType
                                                   >> Msg.FilterAlleles
                                                   >> dispatch)
                  renderMultipleSelectFilter filters.mutation (Mutation
                                                 >> Msg.FilterAlleles
                                                 >> dispatch)
                  renderMultipleSelectFilter filters.zygosity (Zygosity
                                                 >> Msg.FilterAlleles
                                                 >> dispatch) ]
            match filters.geneOverlap with
            | None -> res
            | Some geneOverlapFilter ->
                List.concat [res ; [ renderSingleSelectFilter geneOverlapFilter (GeneOverlap >> Msg.FilterAlleles >> dispatch) ] ]

    let filterSummary =
        match model.alleleFilters with
        | None -> str ""
        | Some filters ->
            span []
                [ if filters.mutation.items |> List.exists (fun item -> item.isSelected) then yield filters.mutation |> renderMultipleSelectFilterSummary
                  if filters.phenotype.items |> List.exists (fun item -> item.isSelected) then yield filters.phenotype |> renderMultipleSelectFilterSummary
                  if filters.zygosity.items |> List.exists (fun item -> item.isSelected) then yield filters.zygosity |> renderMultipleSelectFilterSummary
                  if filters.alleleType.items |> List.exists (fun item -> item.isSelected) then yield filters.alleleType |> renderMultipleSelectFilterSummary
                  match filters.geneOverlap with
                  | None -> ()
                  | Some filter -> if Option.isSome filter.selectedItem then yield renderSingleSelectFilterSummary filter ]

    match alleles with
    | RemoteData.NotAsked -> str ""
    | RemoteData.Loading -> Loading
    | RemoteData.Failure error -> str ("Error: " + error)
    | RemoteData.Success alleles ->
        match alleles with
        | [] ->
            div [ Class "no-results" ]
                [ str "No results found for: "
                  em [] [ str (String.concat ", " phenotypes) ] ]
        | _ ->
            let allAlleles = alleles
            let filteredAlleles =
                filterAlleles model.alleleFilters allAlleles

            let downloadGenesUrl =
                let data =
                    filteredAlleles
                    |> List.map (fun allele -> allele.gene)
                    |> String.concat "\n"
                Components.Blob.createBlobURL data "text/csv"

            let downloadResultsUrl =
                let headerRow =
                    [ "MGI identifier"
                      "Gene"
                      "Allelic composition"
                      "Genetic background"
                      "Zygosity"
                      "Allele type"
                      "Mutation"
                      "Annotated term"
                      "Publication" ]
                let dataRows =
                    filteredAlleles
                    |> List.map (fun allele ->
                        [ allele.identifier
                          allele.gene
                          allele.name
                          allele.involves
                          allele.zygote
                          allele.alleleType
                          allele.mutation
                          allele.phenotype
                          allele.pubMedReference
                            |> Option.map (fun reference -> sprintf "https://www.ncbi.nlm.nih.gov/pubmed/?term=%s" reference)
                            |> Option.defaultValue "" ])
                let data =
                    List.concat [[headerRow] ; dataRows]
                    |> List.map (fun row -> String.concat "\t" row)
                    |> String.concat "\n"
                Components.Blob.createBlobURL data "text/csv"

            let runAnalysisButton =
                match viewMode with
                | ViewMode.ViewMode.Snapshot ->
                    fragment [] []
                | ViewMode.ViewMode.Workflow ->
                    button [ Class "button is-link is-small"
                             OnClick(fun _ -> dispatch RunAnalysis) ]
                           [ span [ Class "icon is-small" ]
                                 [ i [ Class "far fa-chart-bar" ] [] ]
                             helpTop helpVisible
                                 (div [ Style [ Width "200px" ] ]
                                      [ str
                                            "Run pathway analysis for the list of genes in the table below." ])
                                 [ span [] [ str "Run analysis" ] ] ]

            div [ Class "allele-search" ]
                [ h4 [ Class "title is-4" ] [ str "Phenotype associated genes" ]

                  div [ Class "tags--horizontal" ]
                      (alleles
                       |> List.countBy (fun allele -> allele.phenotype)
                       |> List.map
                              (fun (phenotype, count) ->
                              div [ Class "control" ]
                                  [ div [ Class "tags has-addons " ]
                                        [ span [ Class "tag" ] [ str phenotype ]

                                          span [ Class "tag is-success" ]
                                              [ str (string count) ] ] ]))
                  div [ Class "filter-summary" ] (if List.length filteredAlleles = List.length
                                                                                       allAlleles then
                                                      [ span
                                                            [ Class
                                                                  "filter-summary__label" ]
                                                            [ str "Showing:" ]

                                                        span []
                                                            [ sprintf
                                                                  "%d alleles"
                                                                  (List.length
                                                                       filteredAlleles)
                                                              |> str ] ]
                                                  else
                                                      [ span
                                                            [ Class
                                                                  "filter-summary__label" ]
                                                            [ str "Showing:" ]

                                                        span []
                                                            [ sprintf
                                                                  "%d of %d alleles"
                                                                  (List.length
                                                                       filteredAlleles)
                                                                  (List.length
                                                                       allAlleles)
                                                              |> str ]

                                                        span
                                                            [ Class
                                                                  "filter-summary__label" ]
                                                            [ str
                                                                  "Active filters:" ]
                                                        filterSummary ])

                  div [ Class "table-actions" ]
                      [ div [ Class "table-actions__left" ]
                            [ helpTop helpVisible
                                  (div [ Style [ Width "200px" ] ]
                                       [ str
                                             "Use filters to narrow down the list of genes selected for analysis " ])
                                  [ div [ Class "filters" ] filters ] ]


                        div [ Class "table-actions__right" ]
                            [ runAnalysisButton
                              renderDownloadButton "Download results" downloadResultsUrl "results.csv"
                              renderDownloadButton "Download gene list" downloadGenesUrl "genes.csv" ] ]

                  div [ Class "table-container" ]
                      [ Components.DataTable.render
                            helpVisible
                            alleleDataTable
                            filteredAlleles
                            ()
                            [ Class "table is-small is-hoverable is-bordered is-fullwidth table-results" ]
                            (Msg.AlleleDataTableMsg >> dispatch) ] ]


let view helpVisible (viewMode : ViewMode.ViewMode) (model : Model) dispatch =
    let searchInput =
        match viewMode with
        | ViewMode.ViewMode.Snapshot ->
            h1 [ Class "title is-1" ]
               [ str "Phenotype search for "
                 i [ Class "title is-2 has-text-primary" ] [ str (String.concat ", " model.phenotypes) ] ]
        | ViewMode.ViewMode.Workflow ->
            div [ Class "search-input" ]
                [ h1 [ Class "title is-1" ] [ str "Search by phenotype" ]

                  div [ Class "field has-addons" ]
                      [ div [ Class "control is-expanded" ]
                            [ input
                                  [ Class "input is-medium"
                                    Type "text"
                                    Placeholder "Enter one or more phenotypes"
                                    valueOrDefault model.phenotypeInput
                                    OnInput (fun e -> dispatch (PhenotypeInputChanged e.Value))
                                    OnKeyPress (fun e -> dispatch (PhenotypeInputKeyPressed(e.charCode))) ] ]

                        div [ Class "control" ]
                            [ a
                                  [ Class "button is-dark is-medium"
                                    OnClick
                                        (fun _ ->
                                        dispatch
                                            (RequestAlleles model.phenotypeInput)) ]
                                  [ str "Search" ] ] ]

                  div [ Class "example-queries" ]
                      [ str "Example queries: "
                        a [ OnClick (fun _ -> dispatch (SetPhenotypeInput "omphalocele")) ]
                          [ str "omphalocele" ]
                        str " | "
                        a [ OnClick (fun _ -> dispatch (SetPhenotypeInput "thoracoschisis")) ]
                          [ str "thoracoschisis" ]
                        str " | "
                        a [ OnClick (fun _ -> dispatch (SetPhenotypeInput "omphalocele, thoracoschisis")) ]
                          [ str "omphalocele, thoracoschisis" ]
                        str " | "
                        a [ OnClick (fun _ -> dispatch (SetPhenotypeInput "abnormal birth body size")) ]
                          [ str "abnormal birth body size" ] ] ]

    div [ ]
        [ div [ Class "container is-fluid" ]
              [ searchInput

                div [ Class "search-results" ]
                    [ renderAllelesOrError helpVisible viewMode model dispatch ] ] ]
