module Components.DataTable

open Fable.Import.React
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Elmish
open Elmish.React

type ExternalMsg<'ExternalMsg> =
    | NoOp
    | Msg of 'ExternalMsg

type Msg<'ExternalMsg> =
    | ToggleSortBy of int
    | SearchInputChanged of int * string
    | ExternalMessage of ExternalMsg<'ExternalMsg>

type RowIndex = int
type ColumnIndex = int

type ColumnSearch<'Row> =
    { Input : string option
      Match : string -> 'Row -> bool }

type Column<'Row, 'Context, 'ExternalMsg> =
    { name : string
      render : RowIndex -> ColumnIndex -> 'Row -> 'Context -> Dispatch<Msg<'ExternalMsg>> -> Fable.Import.React.ReactElement
      props : RowIndex -> ColumnIndex -> 'Row -> 'Context -> Dispatch<Msg<'ExternalMsg>> -> Fable.Helpers.React.Props.IHTMLProp list
      isSortable : (ColumnIndex -> 'Row -> System.IComparable) option
      isSearchable : ColumnSearch<'Row> option
      help : (Components.Tooltip.Position * ReactElement) option }

    static member Create<'Row>
        ( name : string,
          render : RowIndex -> ColumnIndex -> 'Row -> 'Context -> Dispatch<Msg<'ExternalMsg>> -> Fable.Import.React.ReactElement,
          ?props : (RowIndex -> ColumnIndex -> 'Row -> 'Context -> Dispatch<Msg<'ExternalMsg>> -> Fable.Helpers.React.Props.IHTMLProp list),
          ?isSortable : (ColumnIndex -> 'Row -> System.IComparable) option,
          ?isSearchable : ColumnSearch<'Row> option,
          ?help ) =
            { name = name
              render = render
              props = Option.defaultValue (fun _ _ _ _ _ -> []) props
              isSortable = Option.defaultValue None isSortable
              isSearchable = Option.defaultValue None isSearchable
              help = help }

type SortBy =
    | Nothing
    | Ascending of ColumnIndex
    | Descending of ColumnIndex

type Model<'Row, 'Context, 'ExternalMsg> =
    { columns : Column<'Row, 'Context, 'ExternalMsg> list
      sortBy : SortBy }

let update msg (model : Model<'Row, 'Context, 'ExternalMsg>) : Model<'Row, 'Context, 'ExternalMsg> * ExternalMsg<'ExternalMsg> =
    match msg with
    | SearchInputChanged(index, value) ->
        let newColumns =
            model.columns
            |> List.mapi (fun i column ->
                   if i <> index then column
                   else
                       match column.isSearchable with
                       | None -> column
                       | Some columnSearch ->
                           let newColumnSearch =
                               if value.Trim() = "" then
                                   { columnSearch with Input = None }
                               else { columnSearch with Input = Some value }
                           { column with isSearchable = Some newColumnSearch })
        { model with columns = newColumns }, NoOp
    | ToggleSortBy index ->
        match List.tryItem index model.columns with
        | None -> model, NoOp
        | Some column ->
            match column.isSortable with
            | None -> model, NoOp
            | Some _ ->
                match model.sortBy with
                | Nothing -> { model with sortBy = Ascending index }, NoOp
                | Ascending i ->
                    if i = index then { model with sortBy = Descending index }, NoOp
                    else { model with sortBy = Ascending index }, NoOp
                | Descending i ->
                    if i = index then { model with sortBy = Ascending index }, NoOp
                    else { model with sortBy = Ascending index }, NoOp
    | ExternalMessage msg ->
        model, msg

let render helpVisible (model : Model<'Row, 'Context, 'ExternalMsg>) rows context props (dispatch : Dispatch<Msg<'ExternalMsg>>)=
    let renderHeader columnIndex column =
        match column.isSortable with
        | None -> th [] [ str column.name ]
        | Some _ ->
            let classes =
                match model.sortBy with
                | Nothing -> classList [ "sortable", true ]
                | Ascending sortByIndex ->
                    classList
                        [ "sortable", true
                          "sortable--ascending", sortByIndex = columnIndex ]
                | Descending sortByIndex ->
                    classList
                        [ "sortable", true
                          "sortable--descending", sortByIndex = columnIndex ]

            let content =
                div [ Class "sortable__header" ]
                    [ span [ Class "sortable__label" ] [ str column.name ]
                      span [ Class "sortable__sort-symbol" ] [] ]

            match column.help with
            | None ->
                th [ classes
                     OnClick(fun _ -> ToggleSortBy columnIndex |> dispatch) ]
                    [ content ]
            | Some(position, helpElement) ->
                th [ classes
                     OnClick(fun _ -> ToggleSortBy columnIndex |> dispatch) ]
                    [ Components.Help.help position helpVisible helpElement
                          [ content ] ]

    let header = tr [] (model.columns |> List.mapi renderHeader)

    let renderSearchHeader columnIndex column =
        match column.isSearchable with
        | None -> th [] []
        | Some columnSearch ->
            th []
                [ input
                      [ Class "input is-small"
                        columnSearch.Input
                        |> Option.defaultValue ""
                        |> valueOrDefault

                        OnInput
                            (fun e ->
                            dispatch (SearchInputChanged(columnIndex, e.Value))) ] ]

    let searchHeader =
        if model.columns
           |> List.exists (fun column -> Option.isSome column.isSearchable) then
            tr [] (model.columns |> List.mapi renderSearchHeader)
        else null

    let renderRow columns rowIndex (row : 'Row) =
        columns
        |> List.mapi
               (fun columnIndex column ->
               td (column.props rowIndex columnIndex row context dispatch) [ column.render rowIndex columnIndex row context dispatch ])

    let sortedRows =
        match model.sortBy with
        | Nothing -> rows
        | Ascending columnIndex ->
            let column = List.item columnIndex model.columns
            match column.isSortable with
            | None -> rows
            | Some sortKey -> rows |> List.sortBy (sortKey columnIndex)
        | Descending columnIndex ->
            let column = List.item columnIndex model.columns
            match column.isSortable with
            | None -> rows
            | Some sortKey ->
                rows |> List.sortByDescending (sortKey columnIndex)

    table props
        [ thead [] [ header; searchHeader ]
          tbody []
              (List.mapi
                   (fun rowIndex row ->
                   tr [] (renderRow model.columns rowIndex row)) sortedRows) ]
