module Components.Help

open Fable.Import.React
open Components.Tooltip

let help (position : Position) (isOpen : bool) (helpElement : ReactElement) 
    (elements : ReactElement list) =
    Tooltip [ Html helpElement
              Position position
              Open isOpen
              Animation Scale
              Arrow true
              Distance 20
              Duration 200 ] elements

let helpTop (isOpen : bool) (helpElement : ReactElement) 
    (elements : ReactElement list) = help Top isOpen helpElement elements
let helpBottom (isOpen : bool) (helpElement : ReactElement) 
    (elements : ReactElement list) = help Bottom isOpen helpElement elements
let helpLeft (isOpen : bool) (helpElement : ReactElement) 
    (elements : ReactElement list) = help Left isOpen helpElement elements
let helpRight (isOpen : bool) (helpElement : ReactElement) 
    (elements : ReactElement list) = help Right isOpen helpElement elements
