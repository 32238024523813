module Routing

open System

open Elmish
open Elmish.Browser.UrlParser
open Elmish.Browser.Navigation

open Shared.Snapshot
open Snapshot.Types
open Workflow.Types
open Types

let urlOfRoute route =
    match route with
    | Route.Home -> "/"
    | Route.DataSources -> "data-sources"
    | Route.TermsOfUse -> "terms-of-use"
    | Route.PrivacyPolicy -> "privacy-policy"
    | Route.AlleleSearch -> "/allele-search"
    | Route.Analysis -> "/analysis"
    | Route.Assays -> "/assays"
    | Route.SnapshotHistory -> "/workflows"
    | Route.SnapshotRoot id ->
            sprintf "/workflows/%s" (id.Unwrap.ToString())
    | Route.Snapshot (id, snapshotRoute) ->
        let subRoute =
            match snapshotRoute with
            | Snapshot.Types.Route.AlleleSearch -> "allele-search"
            | Snapshot.Types.Route.Analysis -> "analysis"
            | Snapshot.Types.Route.Assays -> "assays"
        sprintf "/workflows/%s/%s" (id.Unwrap.ToString()) subRoute

let private snapshotRouteParser : Parser<_, Route> =
    oneOf [ map Snapshot.Types.Route.AlleleSearch (s "allele-search")
            map Snapshot.Types.Route.Analysis (s "analysis")
            map Snapshot.Types.Route.Assays (s "assays") ]

let uuid state =
    custom "uuid" (Guid.TryParse >> function
        | true, value -> Ok value
        | _ -> Error "Can't parse uuid"
    ) state

let private curry f x y = f (x, y)

let routeParser : Parser<_,Route> =
    oneOf [ map Route.Home top
            map Route.DataSources (s "data-sources")
            map Route.TermsOfUse (s "terms-of-use")
            map Route.PrivacyPolicy (s "privacy-policy")
            map Route.AlleleSearch (s "allele-search")
            map Route.Analysis (s "analysis")
            map Route.Assays (s "assays")
            map Route.SnapshotHistory (s "workflows")
            map Route.SnapshotRoot (s "workflows" </> map SnapshotId uuid)
            map (curry Route.Snapshot) (s "workflows" </> map SnapshotId uuid </> snapshotRouteParser) ]

let urlUpdate (route : Route option) model =
    let redirectToHome = ({ model with RouteState = Home } : Model), Navigation.modifyUrl (urlOfRoute Route.Home)

    match route with
    | None ->
        redirectToHome

    | Some Route.Home ->
        ({ model with RouteState = Home }, Cmd.none)

    | Some Route.DataSources ->
        ({ model with RouteState = DataSources }, Cmd.none)

    | Some Route.TermsOfUse ->
        ({ model with RouteState = TermsOfUse }, Cmd.none)

    | Some Route.PrivacyPolicy ->
        ({ model with RouteState = PrivacyPolicy }, Cmd.none)

    | Some Route.AlleleSearch ->
        match model.WorkflowState with
        | WorkflowState.AlleleSearch workflowState ->
            { model with RouteState = RouteState.AlleleSearch workflowState }, Cmd.none
        | WorkflowState.Analysis workflowState ->
            { model with RouteState = RouteState.AlleleSearch workflowState.AlleleSearch }, Cmd.none
        | WorkflowState.Assays workflowState ->
            { model with RouteState = RouteState.AlleleSearch workflowState.AlleleSearch }, Cmd.none

    | Some Route.Analysis ->
        match model.WorkflowState with
        | WorkflowState.AlleleSearch workflowState ->
            { model with RouteState = RouteState.AlleleSearch workflowState }, Navigation.modifyUrl (urlOfRoute Route.AlleleSearch)
        | WorkflowState.Analysis workflowState ->
            { model with RouteState = RouteState.Analysis workflowState.Analysis }, Cmd.none
        | WorkflowState.Assays workflowState ->
            { model with RouteState = RouteState.Analysis workflowState.Analysis }, Cmd.none

    | Some Route.Assays ->
        match model.WorkflowState with
        | WorkflowState.AlleleSearch workflowState ->
            { model with RouteState = RouteState.AlleleSearch workflowState }, Navigation.modifyUrl (urlOfRoute Route.AlleleSearch)
        | WorkflowState.Analysis workflowState ->
            { model with RouteState = RouteState.Analysis workflowState.Analysis }, Navigation.modifyUrl (urlOfRoute Route.Analysis)
        | WorkflowState.Assays workflowState ->
            { model with RouteState = RouteState.Assays workflowState.Assays }, Cmd.none

    | Some Route.SnapshotHistory ->
        { model with RouteState = RouteState.SnapshotHistory RemoteData.NotAsked }, Cmd.ofMsg LoadSnapshotsRequested

    | Some (Route.SnapshotRoot id) ->
        model, Cmd.ofMsg (NavigateTo (Route.Snapshot (id, Snapshot.Types.Route.AlleleSearch)))

    | Some (Route.Snapshot (id, route)) ->
        let snapshotModel =
            { Route = route
              Snapshot = RemoteData.NotAsked }
        { model with RouteState = Snapshot snapshotModel }, Cmd.ofMsg (LoadSnapshotRequested id |> SnapshotMsg)
