module Components.Loading

open Fable.Helpers.React
open Fable.Helpers.React.Props

let Loading =
    div [ Class "loading-container" ]
        [ div [ Class "loading" ]
                [ div [ Class "loading-dot" ] []
                  div [ Class "loading-dot" ] []
                  div [ Class "loading-dot" ] []
                  div [ Class "loading-dot" ] [] ] ]
