module Components.DataTableTransfer

open Fable.Import.React
open Elmish.React

module Domain = Components.DataTable

type Column =
    { Name : string
      SearchInput : string option
      Help : (Components.Tooltip.Position * ReactElement) option }

type Model =
    { Columns : Column list
      SortBy : Domain.SortBy }

let fromDomain (domainModel : Domain.Model<'Row, 'Context, 'Msg>) : Model =
    let columns =
        domainModel.columns
        |> List.map (fun domainColumn ->
               { Name = domainColumn.name
                 Help = domainColumn.help
                 SearchInput =
                     match domainColumn.isSearchable with
                     | None -> None
                     | Some columnSearch -> columnSearch.Input })
    { Columns = columns
      SortBy = domainModel.sortBy }

let updateDomainFromTransfer (domainModel : Domain.Model<'Row, 'Context, 'Msg>)
    (transferModel : Model) : Domain.Model<'Row, 'Context, 'Msg> =
    let updatedColumns =
        List.map2 (fun (domainColumn : Domain.Column<'Row, 'Context, 'Msg>) (transferColumn : Column) ->
            let updatedIsSearchable =
                match domainColumn.isSearchable, transferColumn.SearchInput with
                | None, _ -> None
                | Some columnSearch, searchInput ->
                    Some { columnSearch with Input = searchInput }
            { domainColumn with isSearchable = updatedIsSearchable })
            domainModel.columns transferModel.Columns
    { columns = updatedColumns
      sortBy = transferModel.SortBy }
