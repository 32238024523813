module Analysis.GeneSelection

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Shared

let renderGeneTagInDataTable (gene : Gene) (selectedGenes : SelectedGenes) dispatch =
    match selectedGenes.Contains gene with
    | true -> span [ Class "tag tag--gene tag--gene-selected"
                     OnClick (fun ev ->
                         DeselectGene gene
                         |> Components.DataTable.ExternalMsg.Msg
                         |> Components.DataTable.ExternalMessage
                         |> dispatch )]
                   [ str gene ]
    | false -> span [ Class "tag tag--gene is-light"
                      OnClick (fun ev ->
                          SelectGene gene
                          |> Components.DataTable.ExternalMsg.Msg
                          |> Components.DataTable.ExternalMessage
                          |> dispatch )]
                    [ str gene ]

let renderGeneTagInSelectedGenes (gene : Gene) dispatch =
    span [ Class "tag tag--gene is-light"
           OnClick (fun ev -> DeselectGene gene |> dispatch )]
         [ str gene ]
