module SessionStorage

open Fable.Import
open Fable.Core.JsInterop

let storageStateSlug = "workflow-state"

let saveState (state : Workflow.Types.WorkflowState) =
    let transfer = Workflow.Transfer.fromDomain state
    Browser.sessionStorage.setItem (storageStateSlug, toJson transfer)

let loadState : Workflow.Types.WorkflowState option =
    Browser.sessionStorage.getItem storageStateSlug
    |> function
    | null -> None
    | x -> Some(unbox x |> ofJson<Workflow.Transfer.WorkflowState>)
    |> Option.map Workflow.Transfer.toDomain

let clearState () =
    Browser.sessionStorage.removeItem storageStateSlug
