module Analysis.GeneOntology.AdjacencyMatrix

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Components.AdjacencyMatrix
open Shared
open Shared.Tree
open Analysis.Shared
open Types

let sortBy (ordering : AdjacencyMatrixOrdering) rows columns (cells : Cell list) =
    let sortByName (node : Components.AdjacencyMatrix.MatrixNode) = node.Name
    let sortByEdgeCount (node : Components.AdjacencyMatrix.MatrixNode) = node.EdgeCount
    let sortByEdgeCountRev (node : Components.AdjacencyMatrix.MatrixNode) = - node.EdgeCount

    let sortByRowValue row =
        match List.filter (fun (cell : Cell) -> cell.Row = row.Id) cells with
        | [] -> 0.0
        | cells ->
            cells
            |> List.map (fun cell -> cell.Value)
            |> List.max

    let sortByColumnValue column =
        match List.filter (fun (cell : Cell) -> cell.Column = column.Id) cells with
        | [] -> 0.0
        | cells ->
            cells
            |> List.map (fun cell -> cell.Value)
            |> List.max

    let flatSort rows columns rowSort columnSort =
        ( rows |> List.collect Tree.flatten |> List.sortByDescending rowSort,
          columns |> List.collect Tree.flatten |> List.sortByDescending columnSort)

    let hierarchySort rows columns rowSort columnSort =
        ( rows
          |> List.map (Tree.sortBy rowSort)
          |> List.sortBy (Tree.getRootNode >> rowSort)
          |> List.collect Tree.flatten,
          columns
          |> List.map (Tree.sortBy columnSort)
          |> List.sortBy (Tree.getRootNode >> columnSort)
          |> List.collect Tree.flatten)

    match ordering with
    | Hierarchy -> hierarchySort rows columns sortByName sortByEdgeCountRev
    | EdgeCount -> flatSort rows columns sortByEdgeCount sortByEdgeCount
    | EdgeValue -> flatSort rows columns sortByRowValue sortByColumnValue

let renderAdjacencyMatrix genes selectedGenes enrichmentData order dispatch =
    let geneTreeList =
        genes
        |> List.map (fun gene ->
            Leaf { Id = gene
                   Name = gene
                   Selected = Set.contains gene selectedGenes })

    let enrichmentDataTreeList =
        enrichmentData
        |> List.map (fun (item : Enrichr.GeneOntologyEnrichment) ->
            Leaf { Id = item.geneOntologyTerm.name
                   Name = item.geneOntologyTerm.name
                   Selected = Set.isSubset (Set.ofList item.genes) selectedGenes })

    let cells =
        enrichmentData
        |> List.collect (fun (item : Enrichr.GeneOntologyEnrichment) ->
            item.genes
            |> List.map (fun gene ->
                { Row = item.geneOntologyTerm.name
                  Column = gene
                  Value = 1.0 - item.pValue }))

    let data =
        Components.AdjacencyMatrix.createModel
            enrichmentDataTreeList
            geneTreeList
            cells

    let config = {
        CellSize = 20
        TransitionDelay = 5
        TransitionDuration = 1000
    }

    Components.AdjacencyMatrix.render config data (sortBy order) (AdjacencyMatrixMsg >> dispatch)

let renderOrderingChoice msg (label : string) (active : bool) dispatch =
    match active with
    | true ->
        span [ ClassName "adjacency-matrix-ordering__choice selected" ] [ str label ]
    | false ->
        span [ ClassName "adjacency-matrix-ordering__choice" ; OnClick (fun event -> (AdjacencyMatrixOrderingChanged >> dispatch) msg) ] [ str label ]

let renderOrderingChoices (choices : List<AdjacencyMatrixOrdering * string>) (selected : AdjacencyMatrixOrdering) dispatch =
    let choices =
        choices
        |> List.map (fun choice ->
            let msg = fst choice
            let label = snd choice
            let active = msg = selected
            renderOrderingChoice msg label active dispatch)
    div [ ClassName "adjacency-matrix-ordering" ]
        (List.concat [
            [ span [ ClassName "adjacency-matrix-ordering__label" ] [ str "Sort matrix by:"] ]
            choices ])

let render genes selectedGenes (data : Enrichr.GeneOntologyEnrichment list) order dispatch =
    let genes = genes |> List.distinct
    div [] [
        renderOrderingChoices Analysis.Shared.adjacencyMatrixOrderingChoices order dispatch
        renderAdjacencyMatrix genes selectedGenes data order dispatch
    ]
