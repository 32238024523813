module Analysis.Pathways.DataTable

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Components
open Components.Tooltip
open Components.DataTable

open Analysis.Shared
open Types

let dataTable () : DataTable.Model<Pathway, SelectedGenes, GeneSelectionMsg> =
    { sortBy = Nothing
      columns =
          [ Column.Create
                (name = "Pathway",
                 render = (fun _ _ pathway context dispatch ->
                    span [ classList [
                            "tag tag--multiple-gene-selector", true
                            "tag--multiple-gene-selector-selected", Set.isSubset (Set.ofList pathway.genes) context ]
                           OnClick (fun _ ->
                                SelectMultipleGenes pathway.genes
                                |> Components.DataTable.ExternalMsg.Msg
                                |> Components.DataTable.ExternalMessage
                                |> dispatch) ]
                         [ pathway.name |> str ] ),
                 isSortable = Some
                                  (fun _ pathway ->
                                  pathway.name :> System.IComparable),
                 isSearchable = Some
                                    { Input = None
                                      Match =
                                          fun query pathway ->
                                              pathway.name.ToLower()
                                                     .Contains(query.ToLower()) })

            Column.Create
                (name = "pValue",
                 render = (fun _ _ pathway context dispatch ->
                 pathway.pValue
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ pathway ->
                                  pathway.pValue :> System.IComparable),
                 help = (Bottom,
                         div [ Style [ Width "150px" ] ]
                             [ str
                                   "Probability that a given result from a test is due to chance." ]))

            Column.Create
                (name = "Adjusted pValue",
                 render = (fun _ _ pathway context dispatch ->
                 pathway.adjustedPValue
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ pathway ->
                                  pathway.adjustedPValue :> System.IComparable))

            Column.Create
                (name = "z-Score",
                 render = (fun _ _ pathway context dispatch ->
                 pathway.zScore
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ pathway ->
                                  pathway.zScore :> System.IComparable),
                 help = (Bottom,
                         div [ Style [ Width "150px" ] ]
                             [ str
                                   "How many standard deviations above or below the mean." ]))

            Column.Create
                (name = "Combined score",
                 render = (fun _ _ pathway context dispatch ->
                 pathway.combinedScore
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ pathway ->
                                  pathway.combinedScore :> System.IComparable),
                 help = (Right,
                         div [ Style [ Width "150px" ] ]
                             [ str
                                   "log of the p-value from the Fisher exact test multiplied by the z-score of the deviation from the expected rank." ]))

            Column.Create
                (name = "Species",
                 render = (fun _ _ pathway context dispatch -> pathway.species.Info.name |> str),
                 isSortable = Some
                                  (fun _ pathway ->
                                  pathway.species.Info.name :> System.IComparable),
                 isSearchable = Some
                                    { Input = None
                                      Match =
                                          fun query pathway ->
                                              pathway.species.Info.name.ToLower()
                                                     .Contains(query.ToLower()) })

            Column.Create
                (name = "Genes",
                 render = (fun _ _ pathway context dispatch ->
                 pathway.genes
                 |> List.map (fun gene -> Analysis.GeneSelection.renderGeneTagInDataTable gene context dispatch)
                 |> span []),
                 isSearchable = Some
                                    { Input = None
                                      Match =
                                          fun query pathway ->
                                              pathway.genes
                                              |> List.exists
                                                     (fun gene ->
                                                     gene.ToLower()
                                                         .Contains(query.ToLower
                                                                       ())) })
            Column.Create(name = "ID",
                          render = fun _ _ pathway context dispatch ->
                              match pathway.sourceId with
                              | None -> str ""
                              | Some id ->
                                  a [ Href(pathway.source.Info.url id)
                                      Target "_blank" ] [ str id ]) ] }
