module Components.CollapsibleTree

open Fable.Core
open Fable.Core.JsInterop
open Fable.Import.React
open Fable.Helpers.React
open Shared

[<Pojo>]
type Tree = Reactome.PathwayTree

[<Pojo>]
type D3Tree =
    { name : string
      children : D3Tree array option }

let rec d3TreeOfTree tree (pathwayGenes : Map<string,Shared.Enrichr.Genes>)=
    match tree with
    | Reactome.PathwayTree.Leaf node ->
        let genes =
            match pathwayGenes.TryFind node.Id with
            | None -> None
            | Some genes ->
                genes
                |> List.map (fun g -> { name = g ; children = None })
                |> List.toArray
                |> Some
        { name = node.Name
          children = genes}

    | Reactome.PathwayTree.Branch(node, children) ->
        { name = node.Name
          children =
              children
              |> List.toArray
              |> Array.map (fun el -> d3TreeOfTree el pathwayGenes)
              |> Some }

type Props =
    | Data of D3Tree
    | ClassName of string

let inline CollapsibleTree(props : Props list) : ReactElement =
    ofImport "default" "../../Components/CollapsibleTree"
        (keyValueList CaseRules.LowerFirst props) []
