module Assays.Types

open RemoteData
open Shared.InterMine
open Shared.Enrichr
open Shared.ToxCast

type Model =
    { phenotypes : Phenotype list
      genes : Genes
      pathways : Pathways
      assays : RemoteData<AssaysResult, string> }

type ExternalMsg =
    | NoOp

type Msg =
    | RequestAssays of Genes
    | ReceivedAssays of Result<AssaysResult, string>
