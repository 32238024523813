module Analysis.GeneOntology.Types

open Elmish.React

open Components
open Shared

open Analysis.Shared

type View =
    | Table
    | Matrix

type FilterItemData =
    | CombinedScoreFilterItemData of CombinedScoreFilterItemData
    | GeneCountFilterItemData of GeneCountFilterItemData

type Filter = DropDown.MultipleSelect.Model<FilterItemData>

type Filters =
    { combinedScore : Filter
      geneCount : Filter }

type GeneOntologyEnrichment = Shared.Enrichr.GeneOntologyEnrichment

type Model =
    { CurrentView : View
      Data : Enrichr.GeneOntologyEnrichment list
      Filters : Filters
      DataTable : Components.DataTable.Model<GeneOntologyEnrichment, SelectedGenes, GeneSelectionMsg>
      AdjacencyMatrixOrdering : AdjacencyMatrixOrdering }

type FilterMsg =
    | CombinedScore of DropDown.MultipleSelect.Msg<FilterItemData>
    | GeneCount of DropDown.MultipleSelect.Msg<FilterItemData>

type Msg =
    | ChangeView of View
    | Filter of FilterMsg
    | DataTable of DataTable.Msg<GeneSelectionMsg>
    | AdjacencyMatrixOrderingChanged of AdjacencyMatrixOrdering
    | AdjacencyMatrixMsg of AdjacencyMatrix.Msg
