module Snapshot.ViewsHistory

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Types
open RemoteData
open Components.Loading

let private renderSnapshot snapshot dispatch =
    let inputId = sprintf "input-%s" (snapshot.Id.Unwrap.ToString())
    let actions =
      div [ Class "link" ]
          [ a [ Class "button is-small is-warning"
                OnClick (fun _ -> dispatch (NavigateTo (Route.Snapshot (snapshot.Id, Route.AlleleSearch)))) ] [ str "View workflow" ]
            div [ ClassName "copy-snapshot-link"]
                [ div [ ClassName "field has-addons" ]
                      [ p [ ClassName "control" ]
                          [ input [ Id inputId
                                    ClassName "input is-small"
                                    ReadOnly true
                                    Value (sprintf "https://syntox.edelweissconnect.com/workflows/%s" (snapshot.Id.Unwrap.ToString()))
                                    OnFocus (fun ev ->
                                        let input = ev.target :?> Fable.Import.Browser.HTMLInputElement
                                        input.select()) ] ]
                        p [ ClassName "control"
                            OnClick (fun _ ->
                                let input = Fable.Import.Browser.document.getElementById(inputId) :?> Fable.Import.Browser.HTMLInputElement
                                input.select()
                                Fable.Import.Browser.document.execCommand("copy") |> ignore ) ]
                          [ a [ ClassName "button is-static is-small" ; Title "Copy workflow link" ] [ i [ ClassName "fas fa-file-import" ] [ ] ] ] ] ] ]

    div [ Class "timeline-item" ]
        [ div [ Class "timeline-marker" ]
            [ ]
          div [ Class "timeline-content" ]
            [ p [ Class "heading" ]
                [ str (snapshot.Timestamp.ToString()) ]
              p [ ]
                [ str snapshot.Description ]
              actions
            ] ]

let private renderSnapshots snapshots dispatch =
    let years =
        snapshots
        |> List.groupBy (fun snapshot -> snapshot.Timestamp.Year)
        |> List.map (fun (year, items) ->
                div [ ]
                    [ header [ Class "timeline-header" ]
                        [ span [ Class "tag is-medium is-primary" ] [ str (year.ToString()) ] ]
                      div [] (List.map (fun snapshot -> renderSnapshot snapshot dispatch) snapshots)
                ] )

    let timeline =
        if years = [] then
            p [ ] [ str "No saved workflows yet. Go and "
                    a [ OnClick (fun _ -> dispatch (NavigateTo Route.Home))] [ str "create" ]
                    str " one." ]
        else
            div [ Class "timeline" ] years

    div [ Class "container is-fluid" ]
        [ div [ Class "snapshots" ]
              [ h1 [ Class "title is-1" ] [ str "Your saved workflows" ]
                timeline ] ]

let render (snapshots : RemoteData<Snapshot list, string>) dispatch =
    match snapshots with
    | Success snapshots ->
        renderSnapshots snapshots dispatch
    | Failure err ->
        sprintf "Error: %s" err |> str
    | _ -> Loading
