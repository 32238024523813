module Snapshot.Views

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Elmish.React

open Types
open Components.ProgressBar
open Components.Loading

let private renderProgressBar (route : Route) (snapshot : Snapshot) navigateToSnapshotRoute =
    match snapshot.WorkflowState with
    | Workflow.Types.AlleleSearch state ->
        progressBar Active Inactive Inactive
    | Workflow.Types.Analysis state ->
        match route with
        | AlleleSearch ->
            progressBar Active (navigateToSnapshotRoute snapshot Analysis) Inactive
        | Analysis ->
            progressBar (navigateToSnapshotRoute snapshot AlleleSearch) Active Inactive
        | Assays ->
            progressBar Inactive Inactive Inactive
    | Workflow.Types.Assays state ->
        match route with
        | AlleleSearch ->
            progressBar Active (navigateToSnapshotRoute snapshot Analysis) (navigateToSnapshotRoute snapshot Assays)
        | Analysis ->
            progressBar (navigateToSnapshotRoute snapshot AlleleSearch) Active (navigateToSnapshotRoute snapshot Assays)
        | Assays ->
            progressBar (navigateToSnapshotRoute snapshot AlleleSearch) (navigateToSnapshotRoute snapshot Analysis) Active

let renderSnapshot (model : Model) dispatch navigateToSnapshotRoute =

    match model.Snapshot with
    | RemoteData.NotAsked -> str ""
    | RemoteData.Loading -> Loading
    | RemoteData.Failure err -> sprintf "Error: %s" err |> str
    | RemoteData.Success snapshot ->
        let content =
            match model.Route, snapshot.WorkflowState with
            | AlleleSearch, Workflow.Types.AlleleSearch state ->
                AlleleSearch.Views.view false ViewMode.ViewMode.Snapshot state (AlleleSearchSnapshot >> dispatch)
            | AlleleSearch, Workflow.Types.Analysis state ->
                AlleleSearch.Views.view false ViewMode.ViewMode.Snapshot state.AlleleSearch (AlleleSearchSnapshot >> dispatch)
            | AlleleSearch, Workflow.Types.Assays state ->
                AlleleSearch.Views.view false ViewMode.ViewMode.Snapshot state.AlleleSearch (AlleleSearchSnapshot >> dispatch)
            | Analysis, Workflow.Types.Analysis state ->
                Analysis.Views.view false ViewMode.ViewMode.Snapshot state.Analysis (AnalysisSnapshot >> dispatch)
            | Analysis, Workflow.Types.Assays state ->
                Analysis.Views.view false ViewMode.ViewMode.Snapshot state.Analysis (AnalysisSnapshot >> dispatch)
            | Assays, Workflow.Types.Assays state ->
                Assays.Views.view false ViewMode.ViewMode.Snapshot state.Assays (AssaysSnapshot >> dispatch)
            | _, _ ->
                str ""

        div [ Class "snapshot" ]
            [ div [ Class "notification is-warning" ]
                  [ button [ Class "delete" ; OnClick (fun _ ->  dispatch CloseSnapshot) ] []
                    p [] [ b [] [ sprintf "Workflow taken on %s" (snapshot.Timestamp.ToString()) |> str ] ]
                    p [] [ str snapshot.Description ]
                  ]
              div [ Class "snapshot-view" ]
                  [ renderProgressBar model.Route snapshot navigateToSnapshotRoute
                    content
                  ]
            ]
