module AlleleSearch.DataTable

open Fable
open Fable.Helpers.React
open Fable.Helpers.React.Props
open Elmish.React

open Components.DataTable

open Types

let createAlleleDataTable () : Components.DataTable.Model<Allele, unit, unit> =
    let renderSuperscript s =
        let parts =
            System.Text.RegularExpressions.Regex.Split(s, "[<>]")
            |> List.ofArray
        span [] (List.mapi (fun i part ->
                     if (i + 1) % 2 = 1 then str part
                     else sup [] [ str part ]) parts)

    let reference (allele : Allele) =
        match allele.pubMedReference with
        | Option.None -> str "/"
        | Option.Some r ->
            a [ Href("https://www.ncbi.nlm.nih.gov/pubmed/?term=" + r)
                Target "_blank" ] [ str r ]

    { sortBy = Ascending 1
      columns =
          [ Column.Create
                (name = "MGI identifier",
                 render = (fun _ _ allele context dispatch ->
                 a
                     [ Target "_blank"

                       Href
                           ("http://www.informatics.jax.org/marker/"
                            + allele.identifier) ] [ str allele.identifier ]),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.identifier :> System.IComparable))

            Column.Create
                (name = "Gene", render = (fun _ _ allele context dispatch -> str allele.gene),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.gene.ToLower() :> System.IComparable))

            Column.Create
                (name = "Allelic composition",
                 render = (fun _ _ allele context dispatch -> renderSuperscript allele.name),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.name :> System.IComparable))

            Column.Create
                (name = "Genetic background",
                 render = (fun _ _ allele context dispatch -> renderSuperscript allele.involves))

            Column.Create
                (name = "Zygosity",
                 render = (fun _ _ allele context dispatch -> str allele.zygote),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.zygote :> System.IComparable))

            Column.Create
                (name = "Allele type",
                 render = (fun _ _ allele context dispatch -> str allele.alleleType),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.alleleType :> System.IComparable))

            Column.Create
                (name = "Mutation",
                 render = (fun _ _ allele context dispatch -> str allele.mutation),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.mutation :> System.IComparable))

            Column.Create
                (name = "Annotated term",
                 render = (fun _ _ allele context dispatch -> str allele.phenotype),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.phenotype :> System.IComparable))

            Column.Create
                (name = "Publication",
                 render = (fun _ _ allele context dispatch -> reference allele),
                 isSortable = Some
                                  (fun _ allele ->
                                  allele.pubMedReference :> System.IComparable)) ] }
