module Types

open RemoteData
open Shared.Snapshot
open Workflow.Types
open Snapshot.Types

type Route =
    | Home
    | DataSources
    | TermsOfUse
    | PrivacyPolicy
    | AlleleSearch
    | Analysis
    | Assays
    | SnapshotHistory
    | SnapshotRoot of SnapshotId
    | Snapshot of SnapshotId * Snapshot.Types.Route

type SaveWorkflowState =
    | Inactive
    | Initiated
    | InProgress

type WorkflowDescription = string

type RouteState =
    | Home
    | DataSources
    | TermsOfUse
    | PrivacyPolicy
    | AlleleSearch of AlleleSearch.Types.Model
    | Analysis of Analysis.Types.Model
    | Assays of Assays.Types.Model
    | SnapshotHistory of RemoteData<Snapshot list, string>
    | Snapshot of Snapshot.Types.Model

type JwtApiRequest =
    | SaveWorkflow of (WorkflowState * WorkflowDescription)
    | LoadWorkflows

type AuthState =
    | Authenticated of string // string is user name
    | Anonymous
    | Awaiting of JwtApiRequest option

type Msg =
    | SignIn
    | SignOut
    | ReceivedWhoAmI of Result<string, unit>
    | NavigateTo of Route
    | ToggleHelpVisible
    | StartNewWorkflow
    | AlleleSearchMsg of AlleleSearch.Types.Msg
    | AnalysisMsg of Analysis.Types.Msg
    | AssaysMsg of Assays.Types.Msg
    | SnapshotMsg of Snapshot.Types.Msg
    | SaveWorkflowInitiated
    | SaveWorkflowDescriptionChanged of string
    | SaveWorkflowCanceled
    | SaveWorkflowRequested of WorkflowState * WorkflowDescription
    | SaveWorkflowCompleted of Result<unit, string>
    | LoadSnapshotsRequested
    | LoadSnapshotsCompleted of RemoteData<Snapshot list, string>

type Model =
    { RouteState : RouteState
      WorkflowState : WorkflowState
      SaveWorkflowState : SaveWorkflowState
      SaveWorkflowDescription : WorkflowDescription
      HelpVisible : bool
      AuthState : AuthState }
