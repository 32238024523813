module Analysis.Renderers

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Components
open Shared

let renderGenesNotInvolvedInAnalysis (analysis : Analysis) (genes : Set<Gene>) =
    if Set.isEmpty genes then str ""
    else
        div [] [ h3 [ Class "title is-4" ] [ sprintf "Genes not involved in any of the %s" analysis.Info.ItemNamePlural |> str ]
                 div [] [ str (String.concat ", " genes) ] ]

let renderLibraries (resultList : (Library * Result<'item list, string>) list) =
    let libraries =
        resultList
        |> List.map (fun (library, result) ->
               match result with
               | Error _ ->
                   div [ Class "control" ] [ div [ Class "tags has-addons " ] [ span [ Class "tag is-dark" ] [ str library.Info.name ]
                                                                                span [ Class "tag is-danger" ] [ str "ERROR" ] ] ]
               | Ok items ->
                   div [ Class "control" ] [ div [ Class "tags has-addons " ] [ span [ Class "tag is-dark" ] [ str library.Info.name ]
                                                                                span [ Class "tag is-success" ] [ str (items
                                                                                                                       |> List.length
                                                                                                                       |> string) ] ] ])
    div [ Class "analysis__libraries" ] libraries

let renderFilter filter dispatch = div [ Class "filter" ] [ DropDown.MultipleSelect.render filter dispatch ]

let renderFilterSummary (filter : DropDown.MultipleSelect.Model<'FilterItemData>) =
    span [ Class "filter-summary__filter" ] [ span [ Class "filter-summary__filter-name" ] [ str filter.name ]
                                              span [ Class "filter-summary__filter-items" ] [ filter.items
                                                                                              |> List.filter (fun item -> item.isSelected)
                                                                                              |> List.map (fun item -> item.name)
                                                                                              |> String.concat ", "
                                                                                              |> str ] ]

let renderFiltersSummary (analysis : Analysis) (activeFilters : DropDown.MultipleSelect.Model<'FilterItemData> list) allItems filteredItems =
    div [ Class "filter-summary" ] (if List.isEmpty activeFilters |> not then
                                        [ span [ Class "filter-summary__label" ] [ str "Showing:" ]
                                          span [] [ sprintf "%d of %d %s" (List.length filteredItems) (List.length allItems) analysis.Info.ItemNamePlural |> str ]
                                          span [ Class "filter-summary__label" ] [ str "Active filters:" ]
                                          span [] (activeFilters |> List.map renderFilterSummary) ]
                                    else if List.length filteredItems <> List.length allItems then
                                        [ span [ Class "filter-summary__label" ] [ str "Showing:" ]
                                          span [] [ sprintf "%d of %d %s" (List.length filteredItems) (List.length allItems) analysis.Info.ItemNamePlural |> str ] ]
                                    else
                                        [ span [ Class "filter-summary__label" ] [ str "Showing:" ]
                                          span [] [ sprintf "%d %s" (List.length filteredItems) analysis.Info.ItemNamePlural |> str ] ])
