module Analysis.Pathways.Types

open Elmish.React

open RemoteData
open Components
open Shared

open Analysis.Shared

type Pathway = Enrichr.Pathway
type Pathways = Enrichr.Pathways
type PathwaysResult = Enrichr.PathwaysResult

type View =
    | Table
    | Matrix
    | Tree

type FilterItemData =
    | SourceFilterItemData of Pathway
    | SpeciesFilterItemData of Pathway
    | CombinedScoreFilterItemData of CombinedScoreFilterItemData
    | GeneCountFilterItemData of GeneCountFilterItemData

type Filter = DropDown.MultipleSelect.Model<FilterItemData>

type Filters =
    { source : Filter
      species : Filter
      combinedScore : Filter
      geneCount : Filter }

type Model =
    { CurrentView : View
      PathwaysResult : PathwaysResult
      Pathways : Pathways
      PathwayTree : RemoteData<Reactome.PathwayTreeResult, string>
      Filters : Filters
      DataTable : Components.DataTable.Model<Pathway, SelectedGenes, GeneSelectionMsg>
      AdjacencyMatrixOrdering : AdjacencyMatrixOrdering }

type FilterMsg =
    | Source of DropDown.MultipleSelect.Msg<FilterItemData>
    | Species of DropDown.MultipleSelect.Msg<FilterItemData>
    | CombinedScore of DropDown.MultipleSelect.Msg<FilterItemData>
    | GeneCount of DropDown.MultipleSelect.Msg<FilterItemData>

type Msg =
    | ChangeView of View
    | RequestPathwayTree of Pathways
    | ReceivedPathwayTree of Result<Reactome.PathwayTreeResult, string>
    | Filter of FilterMsg
    | DataTable of DataTable.Msg<GeneSelectionMsg>
    | AdjacencyMatrixOrderingChanged of AdjacencyMatrixOrdering
    | AdjacencyMatrixMsg of AdjacencyMatrix.Msg
