module Assays.State

open Elmish

open RemoteData
open Shared.Enrichr

open Types

let createModel phenotypes genes pathways =
        { phenotypes = phenotypes
          genes = genes |> List.map (fun (gene : Gene) -> gene.ToUpper())
          pathways = pathways
          assays = NotAsked }

let update msg model =
    match msg with
    | RequestAssays genes ->
        { model with assays = RemoteData.Loading },
        Cmd.ofAsync Server.api.getAssays genes
            (Ok >> ReceivedAssays)
            (sprintf "%A" >> Error >> ReceivedAssays),
        NoOp
    | ReceivedAssays(Ok assays) ->
        { model with assays = RemoteData.Success assays }, Cmd.none, NoOp
    | ReceivedAssays(Error error) ->
        { model with assays = RemoteData.Failure error }, Cmd.none, NoOp
