module Analysis.GeneOntology.Transfer

open Shared
open Analysis.Shared
open Types

type Model =
    { CurrentView : View
      Data : Enrichr.GeneOntologyEnrichment list
      Filters : Filters
      DataTable : Components.DataTableTransfer.Model
      AdjacencyMatrixOrdering : AdjacencyMatrixOrdering }

let fromDomain (model : Types.Model) : Model =
    { CurrentView = model.CurrentView
      Data = model.Data
      Filters = model.Filters
      DataTable = Components.DataTableTransfer.fromDomain model.DataTable
      AdjacencyMatrixOrdering = model.AdjacencyMatrixOrdering }

let toDomain (library : Enrichr.Library) (transferModel : Model) :  Types.Model =
    { CurrentView = transferModel.CurrentView
      Data = transferModel.Data
      Filters = transferModel.Filters
      DataTable =
        Components.DataTableTransfer.updateDomainFromTransfer
            (DataTable.dataTable library) transferModel.DataTable
      AdjacencyMatrixOrdering = transferModel.AdjacencyMatrixOrdering }
