module Analysis.Types

open RemoteData
open Shared
open AlleleSearch.Types

let usedEnricherLibraries =
    [ Enrichr.Library.WikiPathways ; Enrichr.Library.Reactome ]

type Model =
    { analysis : Analysis
      phenotypes : Phenotype list
      genes : Genes
      selectedGenes : SelectedGenes
      enricherUserListId : RemoteData<Enrichr.UserListId, string>
      pathways : RemoteData<Pathways.Types.Model, string>
      cellularComponents : RemoteData<GeneOntology.Types.Model, string>
      molecularFunctions : RemoteData<GeneOntology.Types.Model, string>
      biologicalProcesses : RemoteData<GeneOntology.Types.Model, string> }

type ExternalMsg =
    | NoOp
    | FindAssays of Model * InterMine.Phenotypes * Enrichr.Genes * Enrichr.Pathways

type Msg =
    | FindAssays
    | SelectAnalysis of Analysis
    | GeneSelectionMsg of GeneSelectionMsg
    | RequestEnricherUserListId
    | ReceivedEnricherUserListId of Result<Enrichr.UserListIdResult, string>
    | RequestPathways
    | ReceivedPathways of Result<Enrichr.PathwaysResult, string>
    | PathwaysAnalysisMsg of Pathways.Types.Msg
    | RequestCellularComponents
    | ReceivedCellularComponents of Result<Enrichr.GeneOntologyEnrichmentResult, string>
    | CellularComponentsAnalysisMsg of GeneOntology.Types.Msg
    | RequestMolecularFunctions
    | ReceivedMolecularFunctions of Result<Enrichr.GeneOntologyEnrichmentResult, string>
    | MolecularFunctionsAnalysisMsg of GeneOntology.Types.Msg
    | RequestBiologicalProcesses
    | ReceivedBiologicalProcesses of Result<Enrichr.GeneOntologyEnrichmentResult, string>
    | BiologicalProcessesAnalysisMsg of GeneOntology.Types.Msg
