namespace Shared

module Enrichr =
    /// See:
    ///   http://amp.pharm.mssm.edu/Enrichr/#stats
    ///   http://amp.pharm.mssm.edu/Enrichr/help#api

    type LibraryInfo =
        { name : string
          slug : string
          url : string -> string }

    type Library =
        | Reactome
        | WikiPathways
        | GeneOntologyBiologicalProcess
        | GeneOntologyCellularComponent
        | GeneOntologyMolecularFunction

        member this.Info =
            match this with
            | WikiPathways ->
                { name = "WikiPathways"
                  slug = "WikiPathways_2016"
                  url = fun id -> "https://www.wikipathways.org/index.php/Pathway:" + id }
            | Reactome ->
                { name = "Reactome"
                  slug = "Reactome_2016"
                  url = fun id -> "https://reactome.org/PathwayBrowser/#/" + id }
            | GeneOntologyBiologicalProcess ->
                { name = "GO Biological Processes"
                  slug = "GO_Biological_Process_2018"
                  url = fun id -> "http://amigo.geneontology.org/amigo/term/" + id}
            | GeneOntologyCellularComponent ->
                { name = "GO Cellular Components"
                  slug = "GO_Cellular_Component_2018"
                  url = fun id -> "http://amigo.geneontology.org/amigo/term/" + id}
            | GeneOntologyMolecularFunction ->
                { name = "GO Molecular Functions"
                  slug = "GO_Molecular_Function_2018"
                  url = fun id -> "http://amigo.geneontology.org/amigo/term/" + id}

    type SpeciesInfo =
        { name : string }

    type Species =
        | HomoSapiens
        | MusMusculus
        | RattusNorvegicus
        | Unknown
        | Other of string

        static member OfString name =
            match name with
            | "Homo sapiens" -> HomoSapiens
            | "Mus musculus" -> MusMusculus
            | "Rattus norvegicus" -> RattusNorvegicus
            | str -> Other str

        member this.Info =
            match this with
            | HomoSapiens -> { name = "Homo sapiens" }
            | MusMusculus -> { name = "Mus musculus" }
            | RattusNorvegicus -> { name = "Rattus norvegicus" }
            | Unknown -> { name = "Unknown" }
            | Other name -> { name = name }

    type UserListId = int
    type UserListIdResult = Result<UserListId, string>

    type Gene = string

    type Genes = Gene list

    type Pathway =
        { name : string
          pValue : float
          adjustedPValue : float
          zScore : float
          combinedScore : float
          genes : Gene list
          source : Library
          sourceId : string option
          species : Species }

    type Pathways = Pathway list

    type PathwaysResult = (Library * Result<Pathways, string>) array

    type GeneOntologyId = string

    type GeneOntologyTerm =
        { id : GeneOntologyId option
          name : string }

    type GeneOntologyEnrichment =
        { source : Library
          genes : Gene list
          geneOntologyTerm : GeneOntologyTerm
          pValue : float
          adjustedPValue : float
          zScore : float
          combinedScore : float }

    type GeneOntologyEnrichmentResult = Result<GeneOntologyEnrichment list, string>

module InterMine =
    type Phenotype = string

    type Phenotypes = Phenotype list

    type Allele =
        { identifier : string
          gene : string
          name : string
          involves : string
          zygote : string
          phenotype : Phenotype
          phenotypeOntologyId : string
          pubMedReference : string option
          alleleType : string
          mutation : string }

    type Alleles = Allele list

module ToxCast =

    type Source =
        { Name : string
          Uri : System.Uri
        }

    type Assay =
        { assayComponentEndpointName : string
          assayDesc : string option
          assayComponentEndpointDesc : string option
          geneSymbol : string option
          intendedTargetType : string option
          intendedTargetFamily : string option
          source : Source
        }

    type Assays = Assay list

    type AssaysResult = Result<Assays, string>

module Reactome =

    type Node = {
        Id : string
        Name : string
    }

    type PathwayTree = Shared.Tree.Tree<Node>

    type PathwayTreeResult = Result<PathwayTree, string>

module Snapshot =

    type SnapshotId =
        | SnapshotId of System.Guid with

        member this.Unwrap =
            match this with
            | SnapshotId id -> id

    type TransferSnapshot =
        { Id : SnapshotId
          Timestamp : System.DateTime
          Description : string
          WorkflowState : string
        }

module Route =
    let builder typeName methodName = sprintf "/api/%s/%s" typeName methodName

type IApiApi =
    { whoAmI : unit -> Async<Result<string, unit>>
      getAlleles : InterMine.Phenotype list -> Async<Result<InterMine.Alleles, string>>
      getEnrichrUserListId :  Enrichr.Genes -> Async<Enrichr.UserListIdResult>
      getPathways : Enrichr.UserListId -> Enrichr.Library list -> Async<Enrichr.PathwaysResult>
      getGeneOntologyEnrichment : Enrichr.UserListId -> Enrichr.Library -> Async<Enrichr.GeneOntologyEnrichmentResult>
      getAssays : Enrichr.Genes -> Async<ToxCast.AssaysResult>
      getPathwayTree : Enrichr.Pathways -> Async<Reactome.PathwayTreeResult>
      LoadWorkflowSnapshot : Snapshot.SnapshotId -> Async<Result<Snapshot.TransferSnapshot, string>> }

// Api for endpoints which require authentication
type IJwtApiApi =
    { SaveWorkflowSnapshot : string -> string -> Async<Result<unit, string>>
      LoadWorkflowSnapshots : unit -> Async<Result<Snapshot.TransferSnapshot list, string>> }
