module Workflow.Transfer

type AlleleSearchState = AlleleSearch.Transfer.Model

type AnalysisState =
    { AlleleSearch : AlleleSearch.Transfer.Model
      Analysis : Analysis.Transfer.Model }

type AssaysState =
    { AlleleSearch : AlleleSearch.Transfer.Model
      Analysis : Analysis.Transfer.Model
      Assays : Assays.Transfer.Model }

type WorkflowState =
    | AlleleSearch of AlleleSearchState
    | Analysis of AnalysisState
    | Assays of AssaysState

let fromDomain (workflow : Types.WorkflowState) : WorkflowState =
    match workflow with
    | Types.AlleleSearch model ->
        AlleleSearch.Transfer.fromDomain model
        |> AlleleSearch
    | Types.Analysis model ->
        { AlleleSearch = AlleleSearch.Transfer.fromDomain model.AlleleSearch
          Analysis = Analysis.Transfer.fromDomain model.Analysis }
        |> Analysis
    | Types.Assays model ->
        { AlleleSearch = AlleleSearch.Transfer.fromDomain model.AlleleSearch
          Analysis = Analysis.Transfer.fromDomain model.Analysis
          Assays = Assays.Transfer.fromDomain model.Assays }
        |> Assays

let toDomain (transferState : WorkflowState) : Types.WorkflowState =
    match transferState with
    | AlleleSearch transferModel ->
        AlleleSearch.Transfer.toDomain transferModel
        |> Types.AlleleSearch
    | Analysis transferModel ->
        { Workflow.Types.AnalysisState.AlleleSearch = AlleleSearch.Transfer.toDomain transferModel.AlleleSearch
          Workflow.Types.AnalysisState.Analysis = Analysis.Transfer.toDomain transferModel.Analysis }
        |> Types.Analysis
    | Assays transferModel ->
        { Workflow.Types.AssaysState.AlleleSearch = AlleleSearch.Transfer.toDomain transferModel.AlleleSearch
          Workflow.Types.AssaysState.Analysis = Analysis.Transfer.toDomain transferModel.Analysis
          Workflow.Types.AssaysState.Assays = Assays.Transfer.toDomain transferModel.Assays }
        |> Types.Assays
