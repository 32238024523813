module Analysis.GeneOntology.Views

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Analysis.Shared
open Analysis.Renderers
open Types

type ViewProps =
    { HelpVisible : bool
      Library : Library
      Analysis : Analysis
      Genes : Genes
      SelectedGenes : SelectedGenes }

let genesNotInvolvedInAnalysis (genes : Genes) (data : GeneOntologyEnrichment list) =
    let allGenes = Set genes
    let involvedGenes =
        data
        |> List.collect (fun el -> el.genes)
        |> Set
    allGenes - involvedGenes

let renderDataTable model dispatch props filteredData =
    Components.DataTable.render
        props.HelpVisible
        model.DataTable
        filteredData
        props.SelectedGenes
        [ Class "table is-small is-hoverable is-bordered is-fullwidth table-results" ]
        (DataTable >> dispatch)

let renderFilters model dispatch =
    [ renderFilter (model.Filters.combinedScore) (FilterMsg.CombinedScore >> Filter >> dispatch)
      renderFilter model.Filters.geneCount (FilterMsg.GeneCount >> Filter >> dispatch) ]

let renderDataTableView model dispatch props filteredData =
    [ renderDataTable model dispatch props filteredData
      renderGenesNotInvolvedInAnalysis props.Analysis (genesNotInvolvedInAnalysis props.Genes filteredData) ]

let renderViewToggle model dispatch =
    div [ Class "tabs is-toggle is-toggle-rounded is-small" ]
        [ ul [] [ li [ Class(if model.CurrentView = Table then "is-active" else "") ]
                    [ a [ OnClick(fun _ -> dispatch (ChangeView Table)) ]
                        [ span [ Class "icon is-small" ] [ i [ Class "fa fa-table" ] [] ]
                          span [] [ str "Table view" ] ] ]
                  li [ Class(if model.CurrentView = Matrix then "is-active" else "") ]
                    [ a [ OnClick(fun _ -> dispatch (ChangeView Matrix)) ]
                        [ span [ Class "icon is-small" ] [ i [ Class "far fa-chart-bar" ] [] ]
                          span [] [ str "Matrix view" ] ] ] ] ]

let render model dispatch (props : ViewProps) =
    let filteredData =
        model.Data
        |> Filters.filterAndSearch model.Filters model.DataTable

    let activeFilters =
        [ model.Filters.combinedScore ; model.Filters.geneCount ]
        |> List.filter (fun filter -> filter.items |> List.exists (fun item -> item.isSelected))

    let renderedView =
        match model.CurrentView with
        | Table ->
            renderDataTableView model dispatch props filteredData
        | Matrix ->
            [ AdjacencyMatrix.render props.Genes props.SelectedGenes filteredData model.AdjacencyMatrixOrdering dispatch ]

    [ renderLibraries [(props.Library, Ok model.Data)]
      renderFiltersSummary props.Analysis activeFilters model.Data filteredData
      div [ Class "analysis__controls" ] [ div [ Class "analysis__filters filters" ] (renderFilters model dispatch)
                                           div [ Class "analysis__view-toggle" ] [ renderViewToggle model dispatch ] ]
      div [ Class "analysis__results" ] renderedView ]
