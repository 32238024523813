module RemoteData

type RemoteData<'value, 'error> =
    | NotAsked
    | Loading
    | Success of 'value
    | Failure of 'error

    member this.hasCompleted =
        match this with
        | NotAsked
        | Loading -> false
        | Success _
        | Failure _ -> true

let map f data =
    match data with
    | NotAsked -> NotAsked
    | Loading -> Loading
    | Success x -> Success (f x)
    | Failure e -> Failure e

let toTransfer data =
  match data with
  | Success v -> Some v
  | _ -> None

let mapToTransfer f data =
  match data with
  | Success x -> Some (f x)
  | _ -> None

let fromTransfer data =
  match data with
  | Some v -> Success v
  | None -> NotAsked

let mapFromTransfer f data =
  match data with
  | Some x -> Success (f x)
  | None -> NotAsked
