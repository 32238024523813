module State

open Elmish
open Elmish.Browser.Navigation
open Fable.Import

open Types

let makeJwtApiRequest model (request : JwtApiRequest) : Model * Cmd<Msg> =
    match model.AuthState with
    | Authenticated _ ->
        match request with
        | SaveWorkflow (workflowState, workflowDescription) ->
            let serializedWorkflowState =
                workflowState
                |> Workflow.Transfer.fromDomain
                |> Fable.Core.JsInterop.toJson
            model, Cmd.ofAsync
                (Server.jwtApi.SaveWorkflowSnapshot serializedWorkflowState) workflowDescription
                SaveWorkflowCompleted
                (sprintf "%A" >> Error >> SaveWorkflowCompleted)
        | LoadWorkflows ->
            let toDomain transferSnapshotsResult =
                match transferSnapshotsResult with
                | Error err ->
                    RemoteData.Failure err
                | Ok transferSnapshots ->
                    transferSnapshots
                    |> List.map (fun transfer -> Snapshot.Transfer.toDomain transfer)
                    |> RemoteData.Success
            model, Cmd.ofAsync
                Server.jwtApi.LoadWorkflowSnapshots ()
                (toDomain >> LoadSnapshotsCompleted)
                (sprintf "%A" >> RemoteData.Failure >> LoadSnapshotsCompleted)
    | Anonymous ->
        model, Cmd.ofMsg SignIn
    | Awaiting _ ->
        {model with AuthState = Awaiting (Some request) }, Cmd.none

let update msg model : Model * Cmd<Msg> =
    match msg with
    | ToggleHelpVisible ->
        { model with HelpVisible = not model.HelpVisible }, Cmd.none

    | SignIn ->
        SessionStorage.saveState model.WorkflowState
        Browser.window.location.href <- sprintf "/login?next=%s" (Browser.window.location.pathname)
        model, Cmd.none

    | SignOut ->
        SessionStorage.clearState()
        Browser.window.location.href <- "/logout"
        model, Cmd.none

    | ReceivedWhoAmI (Ok user) ->

        let cmd =
            match model.AuthState with
            | Awaiting (Some (JwtApiRequest.SaveWorkflow state)) ->
                Cmd.ofMsg (SaveWorkflowRequested (model.WorkflowState, model.SaveWorkflowDescription))
            | Awaiting (Some (JwtApiRequest.LoadWorkflows)) ->
                Cmd.ofMsg LoadSnapshotsRequested
            | _ ->
                Cmd.none

        { model with AuthState = Authenticated user }, cmd

    | ReceivedWhoAmI (Error ()) ->
        let cmd =
            match model.AuthState with
            | Awaiting (Some _) ->
                Cmd.ofMsg SignIn
            | _ ->
                Cmd.none

        model, cmd

    | NavigateTo route ->
        model, Navigation.newUrl (Routing.urlOfRoute route)

    | StartNewWorkflow ->
        let newAlleleSearchModel = AlleleSearch.State.initialState
        { model with RouteState = RouteState.AlleleSearch newAlleleSearchModel
                     WorkflowState = Workflow.Types.WorkflowState.AlleleSearch newAlleleSearchModel
        }, Navigation.newUrl (Routing.urlOfRoute Route.AlleleSearch)

    | AlleleSearchMsg msg ->
        let state =
            match model.WorkflowState with
            | Workflow.Types.WorkflowState.AlleleSearch state -> state
            | Workflow.Types.WorkflowState.Analysis state -> state.AlleleSearch
            | Workflow.Types.WorkflowState.Assays state -> state.AlleleSearch

        let newAlleleSearchModel, cmd, externalMsg = AlleleSearch.State.update msg state

        match externalMsg with
        | AlleleSearch.Types.ExternalMsg.NoOp ->
            { model with WorkflowState = Workflow.Types.WorkflowState.AlleleSearch newAlleleSearchModel
                         RouteState = RouteState.AlleleSearch newAlleleSearchModel }, Cmd.map AlleleSearchMsg cmd

        | AlleleSearch.Types.ExternalMsg.RunAnalysis (state, phenotypes, genes) ->
            let analysisModel = Analysis.State.createModel phenotypes genes
            { model with WorkflowState = Workflow.Types.WorkflowState.Analysis { AlleleSearch = state ; Analysis = analysisModel }
                         RouteState = RouteState.Analysis analysisModel
            }, Cmd.batch [
                Navigation.newUrl (Routing.urlOfRoute Route.Analysis)
                Cmd.ofMsg (AnalysisMsg Analysis.Types.Msg.RequestEnricherUserListId) ]

    | AnalysisMsg msg ->
        let state =
            match model.WorkflowState with
            | Workflow.Types.WorkflowState.AlleleSearch _ -> None
            | Workflow.Types.WorkflowState.Analysis state -> Some (state.AlleleSearch, state.Analysis)
            | Workflow.Types.WorkflowState.Assays state -> Some (state.AlleleSearch, state.Analysis)

        match state with
        | None -> model, Cmd.none
        | Some (alleleSearchModel, analysisModel) ->
            let newAnalysisModel, cmd, externalMsg = Analysis.State.update msg analysisModel
            match externalMsg with
            | Analysis.Types.ExternalMsg.NoOp ->
                { model with WorkflowState = Workflow.Types.WorkflowState.Analysis { AlleleSearch = alleleSearchModel ; Analysis = newAnalysisModel }
                             RouteState = RouteState.Analysis newAnalysisModel }, Cmd.map AnalysisMsg cmd
            | Analysis.Types.ExternalMsg.FindAssays (_, phenotypes, genes, pathways) ->
                let assaysModel = Assays.State.createModel phenotypes genes pathways
                let newWorkflowState : Workflow.Types.WorkflowState =
                    Workflow.Types.WorkflowState.Assays { AlleleSearch = alleleSearchModel
                                                          Analysis = analysisModel
                                                          Assays = assaysModel }
                { model with WorkflowState = newWorkflowState
                             RouteState = RouteState.Assays assaysModel
                }, Cmd.batch [
                    Navigation.newUrl (Routing.urlOfRoute Route.Assays)
                    Cmd.ofMsg (AssaysMsg (Assays.Types.Msg.RequestAssays genes)) ]

    | AssaysMsg msg ->
        let state =
            match model.WorkflowState with
            | Workflow.Types.WorkflowState.AlleleSearch _ -> None
            | Workflow.Types.WorkflowState.Analysis state -> None
            | Workflow.Types.WorkflowState.Assays state -> Some (state.AlleleSearch, state.Analysis, state.Assays)

        match state with
        | None -> model, Cmd.none
        | Some (alleleSearchModel, analysisModel, assaysModel) ->
            let newAssaysModel, cmd, externalMsg = Assays.State.update msg assaysModel
            match externalMsg with
            | Assays.Types.ExternalMsg.NoOp ->
                { model with WorkflowState = Workflow.Types.WorkflowState.Assays { AlleleSearch = alleleSearchModel ; Analysis = analysisModel ; Assays = newAssaysModel }
                             RouteState = RouteState.Assays newAssaysModel }, Cmd.map AssaysMsg cmd

    | SnapshotMsg msg ->
        match model.RouteState with
        | Snapshot snapshotModel ->
            let newSnapshotModel, cmd, externalMsg = Snapshot.State.update msg snapshotModel
            match externalMsg with
            | Snapshot.Types.ExternalMsg.NoOp ->
                { model with RouteState = Snapshot newSnapshotModel }, Cmd.map SnapshotMsg cmd
            | Snapshot.Types.ExternalMsg.LoadWorkflowRequested snapshotId ->
                model, Cmd.ofAsync
                    Server.api.LoadWorkflowSnapshot snapshotId
                    (Snapshot.Types.LoadSnapshotCompleted >> SnapshotMsg)
                    (sprintf "%A" >> Error >> (Snapshot.Types.LoadSnapshotCompleted >> SnapshotMsg))
            | Snapshot.Types.ExternalMsg.CloseSnapshot ->
                model, Cmd.ofMsg (NavigateTo Route.SnapshotHistory)
        | _ ->
            model, Cmd.none

    | SaveWorkflowInitiated ->
        { model with SaveWorkflowState = Initiated }, Cmd.none

    | SaveWorkflowDescriptionChanged description ->
        { model with SaveWorkflowDescription = description }, Cmd.none

    | SaveWorkflowCanceled ->
        { model with SaveWorkflowState = Inactive ; SaveWorkflowDescription = "" }, Cmd.none

    | SaveWorkflowRequested (workflowState, workflowDescription) ->
        makeJwtApiRequest
            { model with SaveWorkflowState = InProgress }
            (JwtApiRequest.SaveWorkflow (workflowState, workflowDescription))

    | SaveWorkflowCompleted result ->
        match result with
        | Error err ->
            // TODO: display error message
            { model with SaveWorkflowState = Inactive ; SaveWorkflowDescription = "" }, Cmd.none
        | Ok () ->
            // TODO: display success messages
            { model with SaveWorkflowState = Inactive ; SaveWorkflowDescription = "" }, Cmd.none

    | LoadSnapshotsRequested ->
        makeJwtApiRequest model JwtApiRequest.LoadWorkflows

    | LoadSnapshotsCompleted snapshots ->
        match model.RouteState with
        | SnapshotHistory _ ->

            let newRouteState = SnapshotHistory snapshots
            { model with RouteState = newRouteState }, Cmd.none
        | _ ->
            model, Cmd.none
