module Server

open Shared
open Fable.Remoting.Client

let api : IApiApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IApiApi>()

let jwtApi : IJwtApiApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IJwtApiApi>()
