module AlleleSearch.Transfer

open Types
open DataTable

type Model =
    { phenotypes : Phenotype list
      alleles : Alleles option
      alleleFilters : AlleleFilters option
      alleleDataTable : Components.DataTableTransfer.Model }

let fromDomain (model : AlleleSearch.Types.Model) : Model =
    { phenotypes = model.phenotypes
      alleles = RemoteData.toTransfer model.alleles
      alleleFilters = model.alleleFilters
      alleleDataTable =
           Components.DataTableTransfer.fromDomain model.alleleDataTable }

let toDomain (transferModel : Model) : AlleleSearch.Types.Model =
    { phenotypeInput = String.concat ", " transferModel.phenotypes
      phenotypes = transferModel.phenotypes
      alleles = RemoteData.fromTransfer transferModel.alleles
      alleleFilters = transferModel.alleleFilters
      alleleDataTable =
          Components.DataTableTransfer.updateDomainFromTransfer
              (createAlleleDataTable()) transferModel.alleleDataTable }
