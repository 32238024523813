module Analysis.GeneOntology.DataTable

open Fable.Helpers.React
open Fable.Helpers.React.Props

open Components
open Components.Tooltip
open Components.DataTable
open Shared.Enrichr

open Analysis.Shared

let dataTable library : DataTable.Model<GeneOntologyEnrichment, SelectedGenes, GeneSelectionMsg> =
    let name =
        match library with
        | GeneOntologyBiologicalProcess -> "Biological process"
        | GeneOntologyCellularComponent -> "Cellular component"
        | GeneOntologyMolecularFunction -> "Molecular function"
        | _ -> "Name"

    { sortBy = Nothing
      columns =
          [ Column.Create
                (name = name,
                 render = (fun _ _ row context dispatch ->
                    span [ classList [
                                "tag tag--multiple-gene-selector", true
                                "tag--multiple-gene-selector-selected", Set.isSubset (Set.ofList row.genes) context ]
                           OnClick (fun _ ->
                                SelectMultipleGenes row.genes
                                |> Components.DataTable.ExternalMsg.Msg
                                |> Components.DataTable.ExternalMessage
                                |> dispatch) ]
                          [ row.geneOntologyTerm.name |> str ]),
                 isSortable =
                    Some (fun _ row -> row.geneOntologyTerm.name :> System.IComparable),
                 isSearchable =
                    Some { Input = None
                           Match = fun query row -> row.geneOntologyTerm.name.ToLower().Contains(query.ToLower()) })

            Column.Create
                (name = "pValue",
                 render = (fun _ _ row context dispatch ->
                 row.pValue
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ row ->
                                  row.pValue :> System.IComparable),
                 help = (Top,
                         div [ Style [ Width "150px" ] ]
                             [ str
                                   "Probability that a given result from a test is due to chance." ]))

            Column.Create
                (name = "Adjusted pValue",
                 render = (fun _ _ row context dispatch ->
                 row.adjustedPValue
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ row ->
                                  row.adjustedPValue :> System.IComparable))

            Column.Create
                (name = "z-Score",
                 render = (fun _ _ row context dispatch ->
                 row.zScore
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ row ->
                                  row.zScore :> System.IComparable),
                 help = (Bottom,
                         div [ Style [ Width "150px" ] ]
                             [ str "How many standard deviations above or below the mean." ]))

            Column.Create
                (name = "Combined score",
                 render = (fun _ _ row context dispatch ->
                 row.combinedScore
                 |> sprintf "%.4f"
                 |> str),
                 isSortable = Some
                                  (fun _ row ->
                                  row.combinedScore :> System.IComparable),
                 help = (Top,
                         div [ Style [ Width "150px" ] ]
                             [ str "log of the p-value from the Fisher exact test multiplied by the z-score of the deviation from the expected rank." ]))

            Column.Create
                (name = "Genes",
                 render = (fun _ _ row context dispatch ->
                 row.genes
                 |> List.map (fun gene -> Analysis.GeneSelection.renderGeneTagInDataTable gene context dispatch)
                 |> span []),
                 isSearchable = Some
                                    { Input = None
                                      Match =
                                          fun query pathway ->
                                              pathway.genes
                                              |> List.exists
                                                     (fun gene ->
                                                     gene.ToLower()
                                                         .Contains(query.ToLower
                                                                       ())) })
            Column.Create
                (name = "ID",
                 render = fun _ _ row context dispatch ->
                     match row.geneOntologyTerm.id with
                     | None -> str ""
                     | Some id ->
                         a [ Href(row.source.Info.url id)
                             Target "_blank" ] [ str id ])
          ] }
