module Analysis.Pathways.Transfer

open Shared
open Analysis.Shared
open Types

type Model =
    { CurrentView : View
      PathwaysResult : PathwaysResult
      Pathways : Pathways
      PathwayTree : Reactome.PathwayTreeResult option
      Filters : Filters
      DataTable : Components.DataTableTransfer.Model
      AdjacencyMatrixOrdering : AdjacencyMatrixOrdering }

let fromDomain (model : Types.Model) : Model =
    { CurrentView = model.CurrentView
      PathwaysResult = model.PathwaysResult
      Pathways = model.Pathways
      PathwayTree = RemoteData.toTransfer model.PathwayTree
      Filters = model.Filters
      DataTable = Components.DataTableTransfer.fromDomain model.DataTable
      AdjacencyMatrixOrdering = model.AdjacencyMatrixOrdering
    }

let toDomain (transferModel : Model) : Types.Model =
    { CurrentView = transferModel.CurrentView
      PathwaysResult = transferModel.PathwaysResult
      Pathways = transferModel.Pathways
      PathwayTree = RemoteData.fromTransfer transferModel.PathwayTree
      Filters = transferModel.Filters
      DataTable =
        Components.DataTableTransfer.updateDomainFromTransfer
              (DataTable.dataTable ()) transferModel.DataTable
      AdjacencyMatrixOrdering = transferModel.AdjacencyMatrixOrdering
    }
