module Analysis.Pathways.State

open Elmish
open Fable.Helpers.Isomorphic

open Components
open Analysis.Shared
open Types

let pathwaysOfPathwaysResult pathwaysResult =
    pathwaysResult
    |> Array.toList
    |> List.collect (fun (_, res) ->
           match res with
           | Error _ -> []
           | Ok pathways -> pathways)

let createModel pathwaysResult =
    let pathways = pathwaysOfPathwaysResult pathwaysResult
    { CurrentView = Table
      PathwaysResult = pathwaysResult
      Pathways = pathways
      PathwayTree = RemoteData.NotAsked
      Filters = Filters.createPathwayFilters pathways
      DataTable = DataTable.dataTable ()
      AdjacencyMatrixOrdering = Hierarchy }

let update model msg =
    match msg with
    | ChangeView view ->
        { model with CurrentView = view }, Cmd.none, NoOp
    | RequestPathwayTree pathways ->
        // Currently, we support ony Reactome pathway tree
        let reactomePathways = pathways |> List.filter (fun p -> p.source = Shared.Enrichr.Reactome)
        { model with PathwayTree = RemoteData.Loading },
        Cmd.ofAsync Server.api.getPathwayTree reactomePathways (ok ReceivedPathwayTree) (error ReceivedPathwayTree), NoOp
    | ReceivedPathwayTree (Ok pathwayTree) ->
        { model with PathwayTree = RemoteData.Success pathwayTree }, Cmd.none, NoOp
    | ReceivedPathwayTree(Error error) ->
        { model with PathwayTree = RemoteData.Failure error }, Cmd.none, NoOp
    | Filter msg ->
        let newFilters =
            match msg with
            | FilterMsg.Source msg ->
                { model.Filters with source = DropDown.MultipleSelect.update msg model.Filters.source }
            | FilterMsg.Species msg ->
                { model.Filters with species = DropDown.MultipleSelect.update msg model.Filters.species }
            | FilterMsg.CombinedScore msg ->
                { model.Filters with combinedScore = DropDown.MultipleSelect.update msg model.Filters.combinedScore }
            | FilterMsg.GeneCount msg -> { model.Filters with geneCount = DropDown.MultipleSelect.update msg model.Filters.geneCount }
        { model with Filters = newFilters }, Cmd.none, NoOp
    | DataTable msg ->
        let newDataTable, externalMsg = DataTable.update msg model.DataTable
        let externalMsg =
            match (externalMsg : DataTable.ExternalMsg<GeneSelectionMsg>) with
            | DataTable.ExternalMsg.NoOp -> NoOp
            | DataTable.ExternalMsg.Msg msg -> GeneSelection msg
        { model with DataTable = newDataTable }, Cmd.none, externalMsg
    | AdjacencyMatrixOrderingChanged ordering ->
        { model with AdjacencyMatrixOrdering = ordering }, Cmd.none, NoOp
    | AdjacencyMatrixMsg msg ->
        let externalMsg =
            match msg with
            | AdjacencyMatrix.ColumnSelected id -> SelectGene id |> GeneSelection
            | AdjacencyMatrix.ColumnDeselected id -> DeselectGene id |> GeneSelection
            | AdjacencyMatrix.RowSelected id
            | AdjacencyMatrix.RowDeselected id ->
                let maybeGeneOntologyEnrichment =
                    model.Pathways
                    |> List.tryFind (fun pathway -> Option.exists (fun sourceId -> sourceId = id) pathway.sourceId)
                match maybeGeneOntologyEnrichment with
                | None -> NoOp
                | Some geneOntologyEnrichment -> SelectMultipleGenes geneOntologyEnrichment.genes |> GeneSelection
        model, Cmd.none, externalMsg
