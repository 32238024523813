module Snapshot.Transfer

open Shared.Snapshot

type Snapshot = TransferSnapshot

let fromDomain (snapshot : Snapshot.Types.Snapshot) : Snapshot =
    { Id = snapshot.Id
      Timestamp = snapshot.Timestamp
      Description = snapshot.Description
      WorkflowState =
        snapshot.WorkflowState
        |> Workflow.Transfer.fromDomain
        |> Fable.Core.JsInterop.toJson
    }

let toDomain (transferSnapshot : Snapshot) : Snapshot.Types.Snapshot =
    { Id = transferSnapshot.Id
      Timestamp = transferSnapshot.Timestamp
      Description = transferSnapshot.Description
      WorkflowState =
        transferSnapshot.WorkflowState
        |> Fable.Core.JsInterop.ofJson<Workflow.Transfer.WorkflowState>
        |> Workflow.Transfer.toDomain
    }
