module Components.ProgressBar

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Elmish.React

open Types
open Components.Help

type StepState =
    | Inactive
    | Active
    | Link of (Fable.Import.React.MouseEvent -> unit)

let private progressBarStep step label state =

    let head =
        match state with
        | Inactive ->
            div [ Class "progress-steps__step" ]
        | Active ->
            div [ Class "progress-steps__step progress-steps__step--active" ]
        | Link onClick ->
            div [ Class "progress-steps__step progress-steps__step--link" ; OnClick onClick ]

    let tail =
        [ div [ ClassName "progress-steps__step-number" ] [ str step ]
          div [ ClassName "progress-steps__step-label" ]
              [ str label ] ]

    head tail

let progressBar step1 step2 step3 =
    let separator =
        div [ Class "progress-steps__separator" ]
            [ i [ Class "fas fa-chevron-right" ] [] ]

    nav [ ClassName "progress-steps" ]
        [ progressBarStep "1" "Search for phenotype-associated genes" step1
          separator
          progressBarStep "2" "Analyse and select genes & pathways" step2
          separator
          progressBarStep "3" "Review gene-associated assays" step3 ]

let saveWorkflowDialog workflowState saveWorkflowState saveWorkflowDescription dispatch =
    div [ classList
            [ "modal save-workflow-dialog", true
              "is-active", saveWorkflowState <> SaveWorkflowState.Inactive ] ]
        [ div [ Class "modal-background" ] [ ]
          div [ Class "modal-card" ]
            [ header [ Class "modal-card-head" ]
                [ p [ Class "modal-card-title" ] [ str "Save workflow" ]
                  button [ Class "delete"
                           Disabled (saveWorkflowState = SaveWorkflowState.InProgress)
                           OnClick (fun _ -> dispatch SaveWorkflowCanceled)
                           HTMLAttr.Custom ("aria-label", "close") ] [ ] ]
              section [ Class "modal-card-body" ]
                [ div [ Class "field" ]
                    [ label [ Class "label" ]
                        [ str "Short workflow description"
                          span [ ClassName "text-muted" ] [ str " - optional" ] ]
                      div [ Class "control" ]
                        [ textarea
                            [ Class "textarea"
                              valueOrDefault saveWorkflowDescription
                              OnInput (fun e -> dispatch (SaveWorkflowDescriptionChanged e.Value)) ]
                            [ ] ] ] ]
              footer [ Class "modal-card-foot" ]
                [ button
                    [ Class "button is-warning"
                      Disabled (saveWorkflowState = SaveWorkflowState.InProgress)
                      OnClick (fun _ -> dispatch (SaveWorkflowRequested (workflowState, saveWorkflowDescription))) ]
                    [ (if saveWorkflowState <> SaveWorkflowState.InProgress then str "Save workflow" else str "Saving...") ]
                  button
                    [ Class "button"
                      Disabled (saveWorkflowState = SaveWorkflowState.InProgress)
                      OnClick (fun _ -> dispatch SaveWorkflowCanceled) ]
                    [ str "Cancel" ] ] ] ]

let progressBarWithSaveStateButton isAuthenticated step1 step2 step3 workflowState canSaveState saveWorkflowState saveWorkflowDescription helpVisible dispatch =
    let props : IHTMLProp list =
        if canSaveState then
            [ Class "button is-warning"
              OnClick (fun e ->
                if isAuthenticated then
                    dispatch SaveWorkflowInitiated
                else
                    dispatch SignIn
              )
            ]
        else
            [ Class "button is-warning" ; Disabled (true) ]
    let saveWorkflowButton =
        button props
            [ helpLeft helpVisible
                  (div [ Style [ Width "200px" ] ] [ str "Save selections and data from the three steps of your workflow." ])
                  [ span [] [ str "Save this workflow" ] ] ]

    div [ ClassName "progress-bar" ]
        [ progressBar step1 step2 step3
          div [ ClassName "save-workflow" ]
              [ saveWorkflowButton
                saveWorkflowDialog workflowState saveWorkflowState saveWorkflowDescription dispatch
              ] ]
