module Analysis.Pathways.Views

open Fable.Helpers.React
open Fable.Helpers.React.Props
open RemoteData
open Shared
open Analysis.Shared
open Analysis.Renderers
open Types

type ViewProps =
    { HelpVisible : bool
      Analysis : Analysis
      Genes : Genes
      SelectedGenes : SelectedGenes }

let genesNotInvolvedInPathways (genes : Genes) (pathways : Pathways) =
    let allGenes = Set genes
    let involvedGenes =
        pathways
        |> List.collect (fun el -> el.genes)
        |> Set
    allGenes - involvedGenes

let renderPathwayTree (pathways : Pathways) (treeRemoteData : RemoteData<Reactome.PathwayTreeResult, string>) =
    match treeRemoteData with
    | RemoteData.NotAsked -> str ""
    | RemoteData.Loading -> Components.Loading.Loading
    | RemoteData.Failure error -> str ("Error: " + error)
    | RemoteData.Success treeResult ->
        match treeResult with
        | Error error -> str ("Error: " + error)
        | Ok tree ->
            let reactomePathwayGenes =
                pathways
                |> List.choose (fun p ->
                       match p.sourceId with
                       | Some id -> Some(id, p.genes)
                       | _ -> None)
                |> Map.ofList
            Components.CollapsibleTree.CollapsibleTree
                [ Components.CollapsibleTree.ClassName "collapsible-tree"
                  Components.CollapsibleTree.Data(Components.CollapsibleTree.d3TreeOfTree tree reactomePathwayGenes) ]

let renderTable model dispatch props filteredPathways =
    Components.DataTable.render
        props.HelpVisible
        model.DataTable
        filteredPathways
        props.SelectedGenes
        [ Class "table is-small is-hoverable is-bordered is-fullwidth table-results" ]
        (Msg.DataTable >> dispatch)

let renderViewToggle model dispatch =
    div [ Class "tabs is-toggle is-toggle-rounded is-small" ]
        [ ul [] [ li [ Class(if model.CurrentView = Table then "is-active" else "") ]
                    [ a [ OnClick(fun _ -> dispatch (ChangeView Table)) ]
                        [ span [ Class "icon is-small" ] [ i [ Class "fa fa-table" ] [] ]
                          span [] [ str "Table view" ] ] ]
                  li [ Class(if model.CurrentView = Matrix then "is-active" else "") ]
                    [ a [ OnClick(fun _ -> dispatch (ChangeView Matrix)) ]
                        [ span [ Class "icon is-small" ] [ i [ Class "far fa-chart-bar" ] [] ]
                          span [] [ str "Matrix view" ] ] ]
                  li [ Class(if model.CurrentView = Tree then "is-active" else "") ]
                        [ a [ OnClick(fun _ -> dispatch (ChangeView Tree)) ]
                            [ span [ Class "icon is-small" ] [ i [ Class "fas fa-sitemap" ] [] ]
                              span [] [ str "Pathway hierarchy" ] ] ] ] ]

let renderView model dispatch props filteredPathways =
    let reactomePathways =
        // AdjacencyMatrix and PathwayTree can only work with reactome pathways for which we have the hierarcy
        filteredPathways |> List.filter (fun (pathway : Pathway) -> pathway.source = Enrichr.Library.Reactome)
    match model.CurrentView with
    | Table ->
        [ renderTable model dispatch props filteredPathways
          renderGenesNotInvolvedInAnalysis props.Analysis (genesNotInvolvedInPathways props.Genes filteredPathways) ]
    | Matrix ->
        [ AdjacencyMatrix.render props.Genes props.SelectedGenes reactomePathways model.PathwayTree model.AdjacencyMatrixOrdering dispatch ]
    | Tree ->
        [ renderPathwayTree reactomePathways model.PathwayTree ]

let render model dispatch (props : ViewProps) =
    let renderedFilters =
        [ renderFilter model.Filters.source (FilterMsg.Source >> Msg.Filter >> dispatch)
          renderFilter model.Filters.species (FilterMsg.Species >> Msg.Filter >> dispatch)
          renderFilter model.Filters.combinedScore (FilterMsg.CombinedScore >> Msg.Filter >> dispatch)
          renderFilter model.Filters.geneCount (FilterMsg.GeneCount >> Msg.Filter >> dispatch) ]

    let activeFilters =
        [ model.Filters.source ; model.Filters.species ; model.Filters.combinedScore ; model.Filters.geneCount ]
        |> List.filter (fun filter -> filter.items |> List.exists (fun item -> item.isSelected))

    let filteredPathways =
        model.Pathways
        |> Filters.filterAndSearch model.Filters model.DataTable

    [ model.PathwaysResult
      |> Array.toList
      |> renderLibraries
      renderFiltersSummary props.Analysis activeFilters model.Pathways filteredPathways
      div [ Class "analysis__controls" ] [ div [ Class "analysis__filters filters" ] renderedFilters
                                           div [ Class "analysis__view-toggle" ] [ renderViewToggle model dispatch ] ]
      div [ Class "analysis__results" ] (renderView model dispatch props filteredPathways) ]
