module Analysis.Transfer

open Shared.InterMine
open Shared
open Types

type Model =
    { Analysis : Analysis
      Phenotypes : Phenotype list
      Genes : Genes
      SelectedGenes : SelectedGenes
      EnricherUserListId : Enrichr.UserListId option
      Pathways : Pathways.Transfer.Model option
      CellularComponents : GeneOntology.Transfer.Model option
      MolecularFunctions : GeneOntology.Transfer.Model option
      BiologicalProcesses : GeneOntology.Transfer.Model option }

let fromDomain (model : Types.Model) : Model =
    { Analysis = model.analysis
      Phenotypes = model.phenotypes
      Genes = model.genes
      SelectedGenes = model.selectedGenes
      EnricherUserListId = RemoteData.toTransfer model.enricherUserListId
      Pathways = RemoteData.mapToTransfer Pathways.Transfer.fromDomain model.pathways
      CellularComponents = RemoteData.mapToTransfer GeneOntology.Transfer.fromDomain model.cellularComponents
      MolecularFunctions = RemoteData.mapToTransfer GeneOntology.Transfer.fromDomain model.molecularFunctions
      BiologicalProcesses = RemoteData.mapToTransfer GeneOntology.Transfer.fromDomain model.biologicalProcesses }

let toDomain (transferModel : Model) : Types.Model =
    { analysis = transferModel.Analysis
      phenotypes = transferModel.Phenotypes
      genes = transferModel.Genes
      selectedGenes = transferModel.SelectedGenes
      enricherUserListId = RemoteData.fromTransfer transferModel.EnricherUserListId
      pathways = RemoteData.mapFromTransfer Pathways.Transfer.toDomain transferModel.Pathways
      cellularComponents = RemoteData.mapFromTransfer (fun data -> GeneOntology.Transfer.toDomain Library.GeneOntologyBiologicalProcess data) transferModel.BiologicalProcesses
      molecularFunctions = RemoteData.mapFromTransfer (fun data -> GeneOntology.Transfer.toDomain Library.GeneOntologyCellularComponent data) transferModel.CellularComponents
      biologicalProcesses = RemoteData.mapFromTransfer (fun data -> GeneOntology.Transfer.toDomain Library.GeneOntologyMolecularFunction data) transferModel.MolecularFunctions }
