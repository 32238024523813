module Analysis.Shared

open Shared

type AnalysisInfo =
    { ItemNamePlural : string }

type Analysis =
    | PathwaysAnalysis
    | CellularComponentsAnalysis
    | MolecularFunctionsAnalysis
    | BiologicalProcessesAnalysis

    member this.Info =
        match this with
        | PathwaysAnalysis -> { ItemNamePlural = "pathways" }
        | CellularComponentsAnalysis -> { ItemNamePlural = "cellular components" }
        | MolecularFunctionsAnalysis -> { ItemNamePlural = "molecular functions" }
        | BiologicalProcessesAnalysis -> { ItemNamePlural = "biological processes" }

type Library = Enrichr.Library

type Gene = Enrichr.Gene
type Genes = Enrichr.Genes

type CombinedScoreFilterItemData =
    | LessOrEqualto5
    | GreaterThan5

type GeneCountFilterItemData =
    | LessOrEqualto2
    | GreaterThan2

type AdjacencyMatrixOrdering =
    | Hierarchy
    | EdgeCount
    | EdgeValue

let adjacencyMatrixOrderingChoices =
    [ (Hierarchy, "Hierarchy")
      (EdgeCount, "Gene count")
      (EdgeValue, "pValue") ]

type SelectedGenes = Set<Gene>

type GeneSelectionMsg =
    | SelectGene of Gene
    | DeselectGene of Gene
    | SelectMultipleGenes of Gene list

type ExternalMsg =
    | NoOp
    | GeneSelection of GeneSelectionMsg

let ok msg =
    Ok >> msg

let error msg =
    sprintf "%A" >> Error >> msg
