module Workflow.Types

type AlleleSearchState = AlleleSearch.Types.Model

type AnalysisState =
    { AlleleSearch : AlleleSearch.Types.Model
      Analysis : Analysis.Types.Model }

type AssaysState =
    { AlleleSearch : AlleleSearch.Types.Model
      Analysis : Analysis.Types.Model
      Assays : Assays.Types.Model }

type WorkflowState =
    | AlleleSearch of AlleleSearchState
    | Analysis of AnalysisState
    | Assays of AssaysState
