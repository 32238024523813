module Client

open Fable.Core.JsInterop
open Elmish
open Elmish.React
open Elmish.Browser.Navigation

open Types
open State

Fable.Core.JsInterop.importSideEffects "whatwg-fetch"

importAll "react-tippy/dist/tippy.css"
importAll "../../styles/main.scss"

let init route =

    let workflowState =
        match SessionStorage.loadState with
        | Some workflow ->
            SessionStorage.clearState()
            workflow
        | _ ->
          Workflow.Types.WorkflowState.AlleleSearch AlleleSearch.State.initialState

    let model =
        { RouteState = Home
          WorkflowState = workflowState
          SaveWorkflowState = Inactive
          SaveWorkflowDescription = ""
          HelpVisible = false
          AuthState = Awaiting None }

    let model, cmd = Routing.urlUpdate route model

    let authCmd : Cmd<Msg> =
        Cmd.ofAsync Server.api.whoAmI () ReceivedWhoAmI (fun _ -> ReceivedWhoAmI (Error ()))

    model,
    Cmd.batch [cmd; authCmd]

#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

Program.mkProgram init update Views.view
|> Program.toNavigable (Elmish.Browser.UrlParser.parsePath Routing.routeParser) Routing.urlUpdate

#if DEBUG
|> Program.withConsoleTrace
|> Program.withHMR
#endif

|> Program.withReact "app"

#if DEBUG
|> Program.withDebugger
#endif

|> Program.run
