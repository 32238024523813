module Analysis.GeneOntology.State

open Elmish

open Components
open Analysis.Shared
open Types
open Analysis

let createModel library data =
    { CurrentView = Table
      Data = data
      Filters = Filters.filters
      DataTable = DataTable.dataTable library
      AdjacencyMatrixOrdering = Hierarchy }

let update model msg =
    match msg with
    | ChangeView view ->
        { model with CurrentView = view }, Cmd.none, NoOp
    | Filter msg ->
        let newFilters =
            match msg with
            | CombinedScore msg ->
                { model.Filters with combinedScore = Components.DropDown.MultipleSelect.update msg model.Filters.combinedScore }
            | GeneCount msg ->
                { model.Filters with geneCount = Components.DropDown.MultipleSelect.update msg model.Filters.geneCount }
        { model with Filters = newFilters }, Cmd.none, NoOp
    | DataTable msg ->
        let newDataTable, externalMsg = Components.DataTable.update msg model.DataTable
        let externalMsg =
            match (externalMsg : Components.DataTable.ExternalMsg<GeneSelectionMsg>) with
            | Components.DataTable.ExternalMsg.NoOp -> NoOp
            | Components.DataTable.ExternalMsg.Msg msg -> GeneSelection msg
        { model with DataTable = newDataTable }, Cmd.none, externalMsg
    | AdjacencyMatrixOrderingChanged ordering ->
        { model with AdjacencyMatrixOrdering = ordering }, Cmd.none, NoOp
    | AdjacencyMatrixMsg msg ->
        let externalMsg =
            match msg with
            | AdjacencyMatrix.ColumnSelected id -> SelectGene id |> GeneSelection
            | AdjacencyMatrix.ColumnDeselected id -> DeselectGene id |> GeneSelection
            | AdjacencyMatrix.RowSelected id
            | AdjacencyMatrix.RowDeselected id ->
                let maybeGeneOntologyEnrichment = model.Data |> List.tryFind (fun (item : GeneOntologyEnrichment)-> item.geneOntologyTerm.name = id)
                match maybeGeneOntologyEnrichment with
                | None -> NoOp
                | Some geneOntologyEnrichment -> SelectMultipleGenes geneOntologyEnrichment.genes |> GeneSelection
        model, Cmd.none, externalMsg
