module Assays.Views

open Fable.Helpers.React
open Fable.Helpers.React.Props
open Components.Loading
open Assays.Types
open Shared.ToxCast

let renderAssaysTable (assays : Assays) =
    let head =
        [ "Gene ID" ; "Source" ; "Assay" ; "Assay and Assay component edpoint descriptions" ; "Intended target type" ; "Intended target family" ]
        |> List.map (fun label -> th [] [ str label ])
        |> tr []

    let rows =
        assays
        |> List.map (fun assay ->
               tr [] [ td [] [ assay.geneSymbol
                               |> Option.defaultValue ""
                               |> str ]
                       td [] [ a [ Href (assay.source.Uri.ToString())
                                   Target "_blank" ] [ str assay.source.Name ] ]
                       td [] [ str assay.assayComponentEndpointName ]
                       td [] [ p [] [ assay.assayDesc
                                      |> Option.defaultValue ""
                                      |> str ]
                               p [] [ assay.assayComponentEndpointDesc
                                      |> Option.defaultValue ""
                                      |> str ] ]
                       td [] [ assay.intendedTargetType
                               |> Option.defaultValue ""
                               |> str ]
                       td [] [ assay.intendedTargetFamily
                               |> Option.defaultValue ""
                               |> str ]
                ] )

    table
        [ Class
              "table is-small is-hoverable is-bordered is-fullwidth table-results" ]
        [ thead [] [ head ]
          tbody [] rows ]

let renderAssays assays =
    match assays with
    | RemoteData.NotAsked -> str ""
    | RemoteData.Loading -> Loading
    | RemoteData.Failure error -> str ("Error: " + error)
    | RemoteData.Success assays ->
        match assays with
        | Error error -> str ("Error: " + error)
        | Ok assays ->
            match assays with
            | [] -> div [ Class "no-results" ] [ str "No assays were found for selected genes." ]
            | assays -> renderAssaysTable assays

let view helpVisible (viewMode : ViewMode.ViewMode) (model : Model) dispatch =

    let selectedGenes =
        model.genes
        |> List.map (fun gene -> span [ Key gene ; Class "tag tag--gene tag--gene-not-selectable is-light" ] [ ofString gene ])
        |> ofList

    div [ ]
        [ div [ Class "container is-fluid" ]
                [ div [ Class "assays-header" ]
                      [ h1 [ Class "title is-1" ]
                           [ str ("Assays for ")
                             i [ Class "title is-2 has-text-primary" ] [ str (String.concat ", " model.phenotypes) ] ] ]
                  div [ Class "selected-genes without-action-button" ]
                      [ span [ Class "selected-genes__label"] [ str "Selected genes:" ]
                        selectedGenes ]
                  (renderAssays model.assays) ] ]
