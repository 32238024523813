module Analysis.Pathways.Filters

open Components.DropDown.MultipleSelect
open Analysis.Shared
open Types

let createPathwayFilters (pathways : Pathways) : Filters =
    { source =
          { name = "Source"
            isActive = false
            items =
                pathways
                |> List.distinctBy (fun p -> p.source.Info.name)
                |> List.map (fun pathway ->
                       { name = pathway.source.Info.name
                         isSelected = false
                         data = FilterItemData.SourceFilterItemData pathway }) }
      species =
          { name = "Species"
            isActive = false
            items =
                pathways
                |> List.distinctBy (fun p -> p.species)
                |> List.map (fun pathway ->
                       { name = pathway.species.Info.name
                         isSelected = false
                         data = FilterItemData.SpeciesFilterItemData pathway }) }
      combinedScore =
          { name = "Combined score"
            isActive = false
            items =
                [ { name = "<= 5"
                    isSelected = false
                    data = FilterItemData.CombinedScoreFilterItemData LessOrEqualto5 }
                  { name = "> 5"
                    isSelected = false
                    data = FilterItemData.CombinedScoreFilterItemData GreaterThan5 } ] }
      geneCount =
          { name = "Gene count"
            isActive = false
            items =
                [ { name = "<= 2 genes"
                    isSelected = false
                    data = FilterItemData.GeneCountFilterItemData LessOrEqualto2 }
                  { name = "> 2 genes"
                    isSelected = false
                    data = FilterItemData.GeneCountFilterItemData GreaterThan2 } ] } }

let filterFunctionOfFilterItemData filterItemData : Pathway -> bool =
    match filterItemData with
    | FilterItemData.SourceFilterItemData pathway -> fun p -> p.source = pathway.source
    | FilterItemData.SpeciesFilterItemData pathway -> fun p -> p.species = pathway.species
    | FilterItemData.CombinedScoreFilterItemData part ->
        match part with
        | LessOrEqualto5 -> fun p -> p.combinedScore <= 5.0
        | GreaterThan5 -> fun p -> p.combinedScore > 5.0
    | FilterItemData.GeneCountFilterItemData part ->
        match part with
        | LessOrEqualto2 -> fun p -> p.genes.Length <= 2
        | GreaterThan2 -> fun p -> p.genes.Length > 2

let filter filters items =
    let filterGroups =
        [ filters.source.GetSelectedItems()
          filters.species.GetSelectedItems()
          filters.combinedScore.GetSelectedItems()
          filters.geneCount.GetSelectedItems() ]
        |> List.map
               (fun filters ->
               [ for item in filters do
                     yield (filterFunctionOfFilterItemData item.data) ])
    if List.collect id filterGroups |> List.isEmpty then items
    else
        filterGroups
        |> List.map (fun filterGroup ->
               if List.isEmpty filterGroup then items |> Set.ofList
               else
                   filterGroup
                   |> List.collect (fun filter -> List.filter filter items)
                   |> Set.ofList)
        |> Set.intersectMany
        |> Set.toList

let search (dataTable : Components.DataTable.Model<Pathway, SelectedGenes, GeneSelectionMsg>) items =
    List.fold (fun pathways (column : Components.DataTable.Column<Pathway, SelectedGenes, GeneSelectionMsg>) ->
        match column.isSearchable with
        | None -> pathways
        | Some { Input = None } -> pathways
        | Some { Input = Some keywords; Match = matchFunction } -> pathways |> List.filter (matchFunction keywords))
        items dataTable.columns

let filterAndSearch (filters : Filters) (dataTable : Components.DataTable.Model<Pathway, SelectedGenes, GeneSelectionMsg>) items =
    items
    |> filter filters
    |> search dataTable
