module Analysis.State

open Elmish
open RemoteData
open Shared
open Types

let createModel phenotypes genes =
    { analysis = PathwaysAnalysis
      phenotypes = phenotypes
      genes = genes |> List.map (fun (gene : Gene) -> gene.ToUpper())
      selectedGenes = Set.empty
      enricherUserListId = NotAsked
      pathways = NotAsked
      cellularComponents = NotAsked
      molecularFunctions = NotAsked
      biologicalProcesses = NotAsked }

let createGeneOntologyModel library result =
    match result with
    | Error error ->
        RemoteData.Failure error
    | Ok result ->
        match result with
        | Ok data -> RemoteData.Success (GeneOntology.State.createModel library data)
        | Error error -> RemoteData.Failure error

let update msg model =
    match msg with
    | FindAssays ->
        match model.pathways with
        | RemoteData.Success pathways ->
            model,
            Cmd.none,
            ExternalMsg.FindAssays (model, model.phenotypes, Set.toList model.selectedGenes , pathways.Pathways)
        | _ -> model, Cmd.none, NoOp
    | SelectAnalysis analysis ->
        { model with analysis = analysis }, Cmd.none, NoOp
    | GeneSelectionMsg msg ->
        match msg with
        | SelectGene gene ->
            { model with selectedGenes = model.selectedGenes.Add gene }, Cmd.none, NoOp
        | DeselectGene gene ->
            { model with selectedGenes = model.selectedGenes.Remove gene }, Cmd.none, NoOp
        | SelectMultipleGenes genes ->
            let countGenes = List.length genes
            let countSelectedGenes = genes |> List.filter (fun gene -> Set.contains gene model.selectedGenes) |> List.length
            if countGenes = countSelectedGenes then
                { model with selectedGenes = model.selectedGenes - Set.ofList genes }, Cmd.none, NoOp
            else
                { model with selectedGenes = model.selectedGenes + Set.ofList genes }, Cmd.none, NoOp
    | RequestEnricherUserListId ->
        { model with enricherUserListId = RemoteData.Loading },
        Cmd.ofAsync Server.api.getEnrichrUserListId model.genes (ok ReceivedEnricherUserListId) (error ReceivedEnricherUserListId),
        NoOp
    | ReceivedEnricherUserListId userListIdResult ->
        match userListIdResult with
        | Ok idResult ->
            match idResult with
            | Ok id ->
                let messages =
                    [ RequestPathways
                      RequestCellularComponents
                      RequestMolecularFunctions
                      RequestBiologicalProcesses ]
                    |> List.map (fun msg -> Cmd.ofMsg msg)
                { model with enricherUserListId = RemoteData.Success id },
                Cmd.batch messages, NoOp
            | Error err ->
                { model with enricherUserListId = RemoteData.Failure err }, Cmd.none, NoOp
        | Error err ->
            { model with enricherUserListId = RemoteData.Failure err }, Cmd.none, NoOp

    | RequestPathways ->
        match model.enricherUserListId with
        | RemoteData.Success id ->
            { model with pathways = RemoteData.Loading },
            Cmd.ofAsync (Server.api.getPathways id) usedEnricherLibraries (ok ReceivedPathways) (error ReceivedPathways),
            NoOp
        | _ ->
            model, Cmd.none, NoOp
    | ReceivedPathways (Ok pathwaysResult) ->
        let newModel = { model with pathways = RemoteData.Success (Pathways.State.createModel pathwaysResult) }
        let cmd =
            Pathways.State.pathwaysOfPathwaysResult pathwaysResult
            |> Pathways.Types.Msg.RequestPathwayTree
            |> PathwaysAnalysisMsg
            |> Cmd.ofMsg
        newModel, cmd, NoOp
    | ReceivedPathways(Error error) ->
        { model with pathways = RemoteData.Failure error }, Cmd.none, NoOp
    | PathwaysAnalysisMsg msg ->
        match model.pathways with
        | RemoteData.Success pathways ->
            let newPathwaysModel, cmd, extraMsg = Pathways.State.update pathways msg
            let extraCmd =
                match extraMsg with
                | Shared.NoOp -> Cmd.none
                | Shared.GeneSelection msg -> GeneSelectionMsg msg |> Cmd.ofMsg
            { model with pathways = RemoteData.Success newPathwaysModel }, Cmd.batch [Cmd.map PathwaysAnalysisMsg cmd ; extraCmd], NoOp
        | _ -> model, Cmd.none, NoOp

    | RequestCellularComponents ->
        match model.enricherUserListId with
        | RemoteData.Success userListId ->
            { model with cellularComponents = RemoteData.Loading },
            Cmd.ofAsync
                (Server.api.getGeneOntologyEnrichment userListId) Library.GeneOntologyCellularComponent
                (ok ReceivedCellularComponents) (error ReceivedCellularComponents),
            NoOp
        | _ ->
            model, Cmd.none, NoOp
    | ReceivedCellularComponents result ->
        { model with cellularComponents = createGeneOntologyModel Library.GeneOntologyCellularComponent result }, Cmd.none, NoOp
    | CellularComponentsAnalysisMsg msg ->
        match model.cellularComponents with
        | RemoteData.Success cellularComponents ->
            let newCellularComponentsModel, cmd, extraMsg = GeneOntology.State.update cellularComponents msg
            let newCmd =
                match extraMsg with
                | Shared.NoOp -> Cmd.none
                | Shared.GeneSelection msg -> GeneSelectionMsg msg |> Cmd.ofMsg
            { model with cellularComponents = RemoteData.Success newCellularComponentsModel }, newCmd, NoOp
        | _ -> model, Cmd.none, NoOp

    | RequestMolecularFunctions ->
        match model.enricherUserListId with
        | RemoteData.Success userListId ->
            { model with molecularFunctions = RemoteData.Loading },
            Cmd.ofAsync
                (Server.api.getGeneOntologyEnrichment userListId) Library.GeneOntologyMolecularFunction
                (ok ReceivedMolecularFunctions) (error ReceivedMolecularFunctions),
            NoOp
        | _ ->
            model, Cmd.none, NoOp
    | ReceivedMolecularFunctions result ->
        { model with molecularFunctions = createGeneOntologyModel Library.GeneOntologyMolecularFunction result }, Cmd.none, NoOp
    | MolecularFunctionsAnalysisMsg msg ->
        match model.molecularFunctions with
        | RemoteData.Success molecularFunctions ->
            let newMolecularFunctionsModel, cmd, extraMsg = GeneOntology.State.update molecularFunctions msg
            let newCmd =
                match extraMsg with
                | Shared.NoOp -> Cmd.none
                | Shared.GeneSelection msg -> GeneSelectionMsg msg |> Cmd.ofMsg
            { model with molecularFunctions = RemoteData.Success newMolecularFunctionsModel }, newCmd, NoOp
        | _ -> model, Cmd.none, NoOp

    | RequestBiologicalProcesses ->
        match model.enricherUserListId with
        | RemoteData.Success userListId ->
            { model with biologicalProcesses = RemoteData.Loading },
            Cmd.ofAsync
                (Server.api.getGeneOntologyEnrichment userListId) Library.GeneOntologyBiologicalProcess
                (ok ReceivedBiologicalProcesses) (error ReceivedBiologicalProcesses),
            NoOp
        | _ ->
            model, Cmd.none, NoOp
    | ReceivedBiologicalProcesses result ->
        { model with biologicalProcesses = createGeneOntologyModel Library.GeneOntologyBiologicalProcess result }, Cmd.none, NoOp
    | BiologicalProcessesAnalysisMsg msg ->
        match model.biologicalProcesses with
        | RemoteData.Success biologicalProcesses ->
            let newBiologicalProcessesModel, cmd, extraMsg = GeneOntology.State.update biologicalProcesses msg
            let newCmd =
                match extraMsg with
                | Shared.NoOp -> Cmd.none
                | Shared.GeneSelection msg -> GeneSelectionMsg msg |> Cmd.ofMsg
            { model with biologicalProcesses = RemoteData.Success newBiologicalProcessesModel }, newCmd, NoOp
        | _ -> model, Cmd.none, NoOp
